import ReactPlayer from 'react-player'
import { useContext, useState } from 'react'
import Vimeo from '@u-wave/react-vimeo';
import { GlobalContext } from '../../../../helpers/context/GlobalState';
import axios from 'axios';

export default function LessonVideo({ embedURL, lesson }) {

    const { globalState } = useContext(GlobalContext);
    const { token, user } = globalState
    const [fetchingVideoProgress, setFetchingVideoProgress] = useState(false)

    if(
        !(
            embedURL.includes('fcloud.boosta.co') || 
            embedURL.includes('bacademy') || 
            embedURL.includes('youtube.com') || 
            embedURL.includes('youtu.be') ||
            embedURL.includes('media.boosta.biz') ||
            embedURL.includes('vimeo.com')
        )
    ){
        embedURL = false
    }

    const onTimeUpdateVimeo = (event) => {
        if(!fetchingVideoProgress) {
            let currentProgress = Math.round(event.percent * 100);
            if (event.duration == Infinity) {
                fechLessonVideoProgress(0);
            } else if(currentProgress >= 30) {
                fechLessonVideoProgress(currentProgress);
            }
        }
    }

    const onTimeUpdateReact = (event) => {
        let currentProgress = Math.round(event.played * 100);
        if (currentProgress >= 30 && ! fetchingVideoProgress) {
            fechLessonVideoProgress(currentProgress);
        }
    }

    const fechLessonVideoProgress = (currentProgress) => {
        setFetchingVideoProgress(true)

        axios({
            method: "post",
            url: `${process.env.REACT_APP_API_PATH}/api/video-progress/`,
            data: {
                'lesson_id': lesson.id,
                'user_id': user.id,
                'progress': currentProgress,
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        })
        .then(response => {
            if (response.status === 200) {
            }
        })
        .catch(error => {
            console.log(error);
        });
    }

    return (
        <>
            {embedURL ?
                <div className="LessonVideo">

                    {embedURL.includes('vimeo.com') ? 

                        <Vimeo
                            video={embedURL}
                            width='auto'
                            className='vimeo-video-wrap'
                            speed={true}
                            onTimeUpdate={onTimeUpdateVimeo}
                        />
                    :
                        <ReactPlayer 
                            url={embedURL}
                            controls={true}
                            config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                            onContextMenu={e => e.preventDefault()}
                            onProgress={onTimeUpdateReact}
                            width='100%'
                            height='auto'
                            className='react-video-wrap'
                        />
                    
                    }
                </div>
            :null}

        </>
    )
}
