import { useState, useEffect } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { seoTitle } from '../../helpers/functions'
import NotFoundContent from '../parts/layout/NotFound/NotFoundContent';
import logo from '../../images/boosta-logo-black.svg'
import preloader from '../../images/preloader.svg'


export default function ResetPasswordChange() {

    const [ fetchingReset, setFetchingReset ] = useState(false)
    const [ userToken, setUserToken ] = useState(false)
    const [ successReset, setSuccessReset ] = useState(false)
    

    //form inputs
    const [ password1, setPassword1 ] = useState('')
    const [ password2, setPassword2 ] = useState('')
    const [ formNotice, setFormNotice ] = useState('')

    
    useEffect(()=>{

        seoTitle('Скидання паролю')

        const queryParams = new URLSearchParams(window.location.search)
        const token = queryParams.get('token')

        setUserToken(token)

    // eslint-disable-next-line
    }, [])


    const fetchResetPass = e => {

        e.preventDefault()

        if(validatePass(password1, password2)){
            
            setFetchingReset(true)
            setFormNotice('')

            let data = {
                token: userToken,
                new_password: password1
            }
    
            axios({
                method: "post",
                url: `${process.env.REACT_APP_API_PATH}/api/auth/reset-password`,
                data: data,
                headers: { "Content-Type": "application/json" },
            })
            .then(response => {
                setFetchingReset(false)
                setPassword1('')
                setPassword2('')
    
                if(response.status === 200){
                    if(response.data.status === 'success'){
                        setSuccessReset(true)
                    } else {
                        setFormNotice(response.data.msg)
                    }
                } else {
                    setFormNotice('Помилка')
                }
            })
            .catch(error => {
                console.log(error);
    
                setFetchingReset(false)
            });
        }

    }

    const validatePass = (password1, password2) => {

        if(password1 !== password2){
            setFormNotice('Паролі не співпадають')
            return false
        }

        if(password1.length < 8 && password2.length < 8){
            setFormNotice('Новий пароль має бути не менше ніж 8 символів')
            return false
        }

        setFormNotice('')
        return true

    }




    if(!userToken){
        return <NotFoundContent />
    }

    return (
        <div className='ResetPasswordChange'>

            <div className="container">

                <form
                    className="resetpass-form" 
                    onSubmit={e=>{
                        fetchResetPass(e)
                    }}
                >
                    <div className="resetpass-form-row">
                        <img className='logo' src={logo} alt="boosta-education-logo" />
                    </div>

                    <div className="resetpass-form-row">
                        <h4>Скидання паролю</h4>
                    </div>

                    {successReset ?

                        <>
                            <div className="resetpass-form-success">
                                Пароль змінено!
                            </div>

                            
                            <div className="resetpass-form-row">
                                <Link
                                    className='btn'
                                    to='/login'
                                >
                                    Увійти
                                </Link>
                            </div>


                            
                        </>
                        
                    :
                        <>
                            <div className="resetpass-form-row">
                                <div className="row-label">Новий пароль</div>
                                <input
                                    required
                                    type="password" 
                                    className='row-input'
                                    value={password1}
                                    onChange={e=>{
                                        setPassword1(e.target.value.replaceAll(' ', ''))
                                    }}
                                
                                />
                            </div>
                            <div className="resetpass-form-row">
                                <div className="row-label">Підтвердження пароля</div>
                                <input
                                    required
                                    type="password"
                                    className='row-input'
                                    value={password2}
                                    onChange={e=>{
                                        setPassword2(e.target.value.replaceAll(' ', ''))
                                    }}
                                />
                            </div>
                            <button
                                type='submit'
                                className='btn btn-reset'
                            >
                                {fetchingReset ? <img src={preloader} alt="loading"/> : 'Змінити пароль'}
                            </button>

                            <div className="resetpass-form-notice">
                                {formNotice}
                            </div>
                        </>
                    }

                    

                    


                    
                </form>
                    
            </div>

        </div>
    )
}
