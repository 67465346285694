import { useEffect, useState } from 'react'
import LessonVideo from '../LessonVideo'
import { formateDateTime } from '../../../../../helpers/functions'

export default function LessonTypeLivestream({lesson}) {

    const [ lessonData, setLessonData ] = useState(null)
    const [ videoUrlEmbed, setVideoUrlEmbed ] = useState(null)
    console.log(lesson.type_livestream[0])

    useEffect(()=>{
        if(lesson.type_livestream.length !== 0){
            setLessonData(lesson.type_livestream[0])

            if(lesson.type_livestream[0].video_embed_url && lesson.type_livestream[0].video_embed_url !== ''){
                setVideoUrlEmbed(lesson.type_livestream[0].video_embed_url)
            }
        }
    // eslint-disable-next-line
    }, [])


    if(lessonData){
        return (
            <div className='LessonTypeLivestream'>
    
                {videoUrlEmbed ?

                    <LessonVideo
                        embedURL={videoUrlEmbed}
                        lesson={lesson}
                    />
                    
                :null}

                {'livestream_starts' in lessonData && lessonData.livestream_starts ? 
                
                    <div className="livestream-starts">
                        {`Заплановано на ${formateDateTime(lessonData.livestream_starts)}`}
                    </div>
                
                :null}
                
                {'lesson_text' in lessonData && lessonData.lesson_text ? 
                    <div className="lesson-main">
                        <div className="lesson-text-heading">в трансляції:</div>
                        <div className="lesson-text" dangerouslySetInnerHTML={{ __html: lessonData.lesson_text }}></div>
                    </div>
                :null}
                
            </div>
        )
    } else return null


    
}
