import { useState, useEffect } from 'react'
import LessonVideo from '../LessonVideo'

export default function TestQuestion({question, index, questionsTotal, handleStoreAnswers, selectedAnswers, lesson}) {


    const [ selectedOptions, setSelectedOptions ] = useState([])
    const [ selectedMatchings, setSelectedMatchings ] = useState([])


    useEffect(()=>{

        if('question_type' in question && (question.question_type === 1 || question.question_type === null)){
            setSelectedOptions(selectedAnswers)
        } 

        if('question_type' in question && question.question_type === 2){
            setSelectedMatchings(selectedAnswers)
        }

    // eslint-disable-next-line
    }, [])

    const handleOptionSelect = (answer) => {
        if(selectedOptions.includes(answer)){
            let updatedSelectedOptions = [...selectedOptions].filter(item=>item!==answer)
            setSelectedOptions(updatedSelectedOptions)
            handleStoreAnswers(index, updatedSelectedOptions)

        } else {
            let updatedSelectedOptions = [...selectedOptions, answer]
            setSelectedOptions(updatedSelectedOptions)
            handleStoreAnswers(index, updatedSelectedOptions)
        }

    }

    const handleMatchingSelect = (answerLeftHash, answerRightHash) => {

        let updatedSelectedMatchings = [...selectedMatchings]

        let hasValue = false
        updatedSelectedMatchings.forEach((row, i) => {

            if(row.includes(answerLeftHash)){
                hasValue = true
                updatedSelectedMatchings[i] = [answerLeftHash, answerRightHash]
                return 
            }
        })

        if(!hasValue){
            updatedSelectedMatchings.push([answerLeftHash, answerRightHash])
        }

        setSelectedMatchings(updatedSelectedMatchings)
        handleStoreAnswers(index, updatedSelectedMatchings)

    }

    
    return (
        <div className="TestQuestion">
            <div className="question-title">
                {`Питання ${index + 1}/${questionsTotal}`}
            </div>

            {question.question_video ? 
                question.question_video.replace(/\s+/g, '').split(',').map((url, index) => {
                    return (
                        <LessonVideo
                            key={index}
                            embedURL={url}
                            lesson={lesson}
                        />
                    )
                })
            :null}

            {question.question_image ? 
                <div className="question-image">
                    <img src={question.question_image} alt="" />
                </div>
            :null}

            <div className="question-text">{question.question_text}</div>

            {'question_type' in question && (question.question_type === 1 || question.question_type === null) ? 
            
                <div className="question-options">
                    {question.test_answers.length > 0 ? 
                    
                        question.test_answers.map((answer, index) => {
                            return (
                                <div 
                                    key={index} 
                                    className={selectedOptions.includes(answer.id) ? 'answer-item answer-item-active' : 'answer-item'}
                                    onClick={()=>{
                                        handleOptionSelect(answer.id)
                                    }}
                                >
                                    <div className="answer-marker"></div>
                                    <div className="answer-value">{answer.answer_text}</div>
                                </div>
                            )
                        })
                    
                    :null}
                </div>
                
            :null}


            {'question_type' in question && question.question_type === 2 ? 
            
                <div className="question-matching">
                    <div className="question-matching-main">

                        {
                            question.test_matching_answers.answers_left &&
                            question.test_matching_answers.answers_right
                        ? 

                            <>
                                {question.test_matching_answers.answers_right.length > 0 ?

                                    
                                    <>
                                    
                                        <div className="matching-row heading">                                            
                                            <div 
                                                className="row-cell"
                                                style={{width: `calc(100% / ${question.test_matching_answers.answers_right.length + 1})`}}
                                            >
                                            </div>
                                                
                                            {question.test_matching_answers.answers_right.map((item, index) =>{
                                                return (
                                                    <div 
                                                        key={index} 
                                                        className="row-cell"
                                                        style={{width: `calc(100% / ${question.test_matching_answers.answers_right.length + 1})`}}
                                                    >
                                                        {item['answer']}
                                                    </div>
                                                )
                                            })}
                                        </div>

                                        {question.test_matching_answers.answers_left.map((itemLeft, index) =>{
                                            return (
                                                <div 
                                                    key={index} 
                                                    className="matching-row"
                                                >
                                                    <div 
                                                        className="row-cell side"
                                                        style={{width: `calc(100% / ${question.test_matching_answers.answers_right.length + 1})`}}
                                                    >
                                                        {itemLeft['answer']}
                                                    </div>
                                                    {question.test_matching_answers.answers_right.map((itemRight, i) =>{
                                                        return (
                                                            <div 
                                                                key={i} 
                                                                className="row-cell marker"
                                                                style={{width: `calc(100% / ${question.test_matching_answers.answers_right.length + 1})`}}
                                                            >

                                                                <div 
                                                                    className={selectedMatchings.some(a => [itemLeft['hash'], itemRight['hash']].every((v, i) => a && v === a[i])) ? "answer-marker selected" : "answer-marker"}
                                                                    onClick={()=>{
                                                                        handleMatchingSelect(itemLeft['hash'], itemRight['hash'])
                                                                    }}
                                                                ></div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            )
                                        })}
                                    </>
                                    
                                :null}

                            </>
                        
                        :null}
                    </div>

                </div>

            :null}
        </div>
    )
}
