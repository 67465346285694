import { useEffect, useState } from 'react'
import LessonVideo from '../LessonVideo'
import { formateDateTime } from '../../../../../helpers/functions'

export default function LessonTypeWebinar({lesson}) {

    const [ lessonData, setLessonData ] = useState(null)
    const [ videoUrlEmbed, setVideoUrlEmbed ] = useState(null)

    useEffect(()=>{
        if(lesson.type_webinar.length !== 0){
            setLessonData(lesson.type_webinar[0])

            if(lesson.type_webinar[0].video_embed_url && lesson.type_webinar[0].video_embed_url !== ''){
                setVideoUrlEmbed(lesson.type_webinar[0].video_embed_url)
            }
        }
    // eslint-disable-next-line
    }, [])


    if(lessonData){
        return (
            <div className='LessonTypeWebinar'>
    
                {videoUrlEmbed ?

                    <LessonVideo
                        embedURL={videoUrlEmbed}
                        lesson={lesson}
                    />
                    
                :null}

                {'webinar_starts' in lessonData && lessonData.webinar_starts ?
                
                    <div className="livestream-starts">
                        {`Заплановано на ${formateDateTime(lessonData.webinar_starts)}`}
                    </div>
                
                :null}
                
                {'lesson_text' in lessonData && lessonData.lesson_text ? 
                    <div className="lesson-main">
                        <div className="lesson-text-heading">в трансляції:</div>
                        <div className="lesson-text" dangerouslySetInnerHTML={{ __html: lessonData.lesson_text }}></div>
                    </div>
                :null}
                
            </div>
        )
    } else return null


    
}
