import { React, useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { GlobalContext } from '../../helpers/context/GlobalState';

const PrivateRoute = ({component: Component, ...rest}) => {

    const { globalState } = useContext(GlobalContext) 

    const { isLogged } = globalState
    
    return (
        <Route 
            {...rest}
            render={props=>{
                if(!isLogged){
                    return <Redirect to="/login"/>
                }else {
                    return <Component {...props}/>
                }
            }}
        />
    )
};

export default PrivateRoute
