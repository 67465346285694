import { isJson } from "../../../../../helpers/functions"

export default function TestResponse({response}) {

    let responseData = isJson(response.respond_data) ? JSON.parse(response.respond_data) : null

    return (
        <div className="TestResponse">
            
            <div className="test-submitted-mark">
                <div className="mark-label">Оцінка</div>
                <div className="mark-value">{response.mark}</div>
            </div>

            {responseData && 'questions' in responseData ?
                <div className="test-response-main">
                    
                    <div className="test-response">
                        {responseData['questions'].map((question, index) => {


                            if(
                                'question_text' in question &&
                                'question_answers' in question &&
                                Array.isArray(question['question_answers']) &&
                                (!('question_type' in question) || question['question_type'] === 1 || question['question_type'] === null)
                            ){
                                

                                return (
                                    <div key={index} className="respond-item">
                                        <div className="question-index">{`питання ${index + 1}/${responseData['questions'].length}`}</div>
                                        <div className="question-text">{question['question_text']}</div>
    
                                        {question['question_answers'].map((answer, i) => {
    
                                            let isSelected = question['user_answers'].includes(answer)
                                            let isCorrect = question['correct_answers'].includes(answer)
    
                                            return (
                                                <div
                                                    key={i} 
                                                    className={
                                                        `question-answer
                                                        ${isSelected ? 'question-answer-active' : ''} 
                                                        ${isCorrect ? 'question-answer-correct' : ''}
                                                        ${isSelected && !isCorrect ? 'question-answer-wrong' : ''}
                                                        `
                                                    }
                                                >
                                                    <div className="answer-marker"></div>
                                                    <div className="answer-value">{answer}</div>
                                                </div>    
                                            )
                                        })}
                                    </div>
                                )
                            }


                            if(
                                'question_text' in question &&
                                'correct_answers' in question &&
                                Array.isArray(question['correct_answers']) &&
                                ('question_type' in question && question['question_type'] === 2)
                            ){
                                

                                return (
                                    <div key={index} className="respond-item">
                                        <div className="question-index">{`питання ${index + 1}/${responseData['questions'].length}`}</div>
                                        <div className="question-text">{question['question_text']}</div>
                                        
                                        <div className="question-matching">
                                            <div className="question-matching-main">

                                                
                                                <div className="matching-row heading">                                            
                                                    <div 
                                                        className="row-cell"
                                                        style={{width: `calc(100% / ${question['correct_answers'].length + 1})`}}
                                                    >
                                                    </div>
                                                        
                                                    {question['correct_answers'].map((item, index) =>{
                                                        return (
                                                            <div 
                                                                key={index} 
                                                                className="row-cell"
                                                                style={{width: `calc(100% / ${question['correct_answers'].length + 1})`}}
                                                            >
                                                                {item[1]}
                                                            </div>
                                                        )
                                                    })}
                                                </div>

                                                {question['correct_answers'].map((itemI, index) =>{
                                                    return (
                                                        <div 
                                                            key={index} 
                                                            className="matching-row"
                                                        >
                                                            <div 
                                                                className="row-cell side"
                                                                style={{width: `calc(100% / ${question['correct_answers'].length + 1})`}}
                                                            >
                                                                {itemI[0]}
                                                            </div>
                                                            {question['correct_answers'].map((itemJ, i) =>{

                                                                const isCorrect = itemI[0] === itemJ[0] && itemI[1] === itemJ[1]
                                                                const isSelected = question['user_answers'].some(a => [itemI[0],itemJ[1]].every((v, i) => a && v === a[i]))

                                                                return (
                                                                    <div 
                                                                        key={i} 
                                                                        style={{width: `calc(100% / ${question['correct_answers'].length + 1})`}}
                                                                        className="row-cell marker"
                                                                    >
                                                                        <div 
                                                                            className={`answer-marker
                                                                                ${isSelected ? 'selected' : ''} 
                                                                                ${isCorrect ? 'correct' : ''}
                                                                                ${isSelected && !isCorrect ? 'wrong' : ''}
                                                                            `}
                                                                        ></div>
                                                                        
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    )
                                                })}
                                                            
                                            </div>

                                        </div>
                                    </div>
                                )
                            }

                            return null

                        })}
                    </div>
                </div> 
                
            :null}

        </div>
    )
}

