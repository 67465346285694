import { useState } from 'react'
import { Link } from 'react-router-dom'
import preloader from '../../images/preloader.svg'
import logo from '../../images/boosta-logo-black.svg'
import axios from 'axios'


export default function ResetPasswordRequest() {

    const [ email, setEmail ] = useState('')
    const [ isLoading, setIsLoading ] = useState(false)
    const [ successResponse, setSuccessResponse ] = useState(null)
    const [ showNotice, setShowNotice ] = useState(false)

    
    const resetPassRequestAction = e => {
        e.preventDefault();

        setShowNotice(false)
        setIsLoading(true)
        setSuccessResponse(null)

        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_PATH}/api/auth/reset-password`,
            params: {
                email: email
            },
            headers: { "Content-Type": "application/json" },
        })
        .then(response => {
            setIsLoading(false)
            setShowNotice(true)
            
            if(response.status === 200){

                if(response.data.status === 'success'){
                    setSuccessResponse(true)
                } else {
                    setEmail('')
                    setSuccessResponse(false)
                }
                
            }
        })
        .catch(error => {
            setSuccessResponse(false)
            setIsLoading(false)
            setShowNotice(true)
            setEmail('')
        });
    }


    return (
        <div className='ResetPasswordRequest'>
            <div className="container">
                <form
                    className="resetpassrequest-form" 
                    onSubmit={e=>{
                        resetPassRequestAction(e)
                    }}
                >
                    <div className="resetpassrequest-form-row">
                        <img className='logo' src={logo} alt="boosta-education-logo" />
                    </div>
                    <div className="resetpassrequest-form-row">
                        <h4>Відновити пароль</h4>
                    </div>

                    {!successResponse ? 
                    
                        <>
                            <div className="resetpassrequest-form-row">
                                <div className="row-label">Email</div>
                                <input
                                    required
                                    type="email" 
                                    className='row-input'
                                    value={email}
                                    onChange={e=>{
                                        setEmail(e.target.value)
                                    }}
                                />
                            </div>
                            
                            <button
                                type='submit'
                                className='btn btn-reset'
                            >
                                {isLoading ? <img src={preloader} alt="loading"/> : 'Скинути'}
                            </button>

                            <Link className='resetpassrequest-form-login' to='/login'>Увійти</Link>
                        </>
                    :null}


                    {showNotice ? 
                        <div className="resetpassrequest-form-notice">
                            {successResponse ? 
                                <>Якщо студент з поштою {<span>{email}</span>} існує, тоді ми відправили тобі посилання на відновлення паролю</> 
                            : 
                                (
                                    successResponse === false ? 'Помилка' : ''
                                )
                            }
                        </div>
                    :null}
                    
                </form>
            </div>
        </div>
    )
}
