import { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router'
import axios from 'axios'
import { GlobalContext } from '../../../../helpers/context/GlobalState'
import preloader from '../../../../images/preloader.svg'
import { saveUserLogin } from '../../../../helpers/functions'
import CertificateRow from './CertificateRow'

export default function BlockCertificates() {

    const { push } = useHistory();

    const { globalState, setUser, setIsLogged, setToken } = useContext(GlobalContext)
    const { token } = globalState

    const [ fetchingCertificates, setFetchingCertificates ] = useState(false)
    const [ certificatesData, setCertificatesInfoData ] = useState([])

    useEffect(()=>{

        setFetchingCertificates(true)

        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_PATH}/api/user-certificates`,
            headers: { 
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`
            },
        })
        .then(response => {
            setFetchingCertificates(false)

            if(response.status === 200){
                setCertificatesInfoData(response.data)
            }
        })
        .catch(error => {
            setFetchingCertificates(false)

            if(error.response && error.response.status === 401){
                setUser(null)
                setToken(null)
                saveUserLogin(false)
                setIsLogged(false)
                push('/login')
            }
        });

    // eslint-disable-next-line
    }, [])
    


    return (
        <div className='BlockCertificates'>
            <div className="block-content">
                <div className="block-head">
                    <div className="block-title">Сертифікати</div> 
                </div>
                <div className="certificates-content-main">

                    {fetchingCertificates ? 
                        <img className='preloader preloader-fw black' src={preloader} alt='loading'/>
                    :
                        <>
                            {
                                'data' in certificatesData && 
                                Array.isArray(certificatesData.data) &&
                                certificatesData.data.length > 0 ? 

                                    <>
                                        <div className="certificates-disclaimer">
                                            Сертифікат можна згенерувати лише один раз. Перед генерацією сертифіката перевірте правильність написання вашого імені та прізвища англійською мовою.
                                        </div>
                                        <div className="certificates-list">
                                            {certificatesData.data.map((course, index) => {
                                                return (
                                                    <CertificateRow key={index} course={course} />
                                                )
                                            })}
                                        </div>
                                    </>
                                
                                : 

                                <div className='no-data'>Немає доступних сертифікатів</div>
                            }
                        </>
                    }
                </div>

            </div>
        </div>
    )
}
