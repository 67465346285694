import { React, useState, useContext } from 'react'
import axios from 'axios'
import { GlobalContext } from '../../helpers/context/GlobalState'
import { Redirect, Link } from 'react-router-dom'
import preloader from '../../images/preloader.svg'
import { saveUserLogin, seoTitle } from '../../helpers/functions'
import logo from '../../images/boosta-logo-black.svg'

export default function LoginPage() {

    const { setUser, setToken, setIsLogged, globalState } = useContext(GlobalContext)
    const { isLogged } = globalState

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [incorrectAuth, setIncorrectAuth] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    seoTitle('Login')

    let loginAction = e => {
        e.preventDefault()

        setIsLoading(true)
        setIncorrectAuth(false)

        let data = {
            username: username,
            password: password
        }


        axios({
            method: "post",
            url: `${process.env.REACT_APP_API_PATH}/api/auth/login`,
            data: data,
            headers: { "Content-Type": "application/json" },
        })
            .then(response => {
                setIsLoading(false)

                if (response.status === 200) {
                    setUser(response.data.user)
                    setToken(response.data.token)
                    saveUserLogin(response.data.user, response.data.token)
                    setIsLogged(true)
                }
            })
            .catch(error => {
                console.log(error);

                setIsLoading(false)
                setIncorrectAuth(true)
            });
    }



    if (!isLogged) {
        return (
            <div className='LoginPage'>
                <div className="container">
                    <form
                        className="login-form"
                        onSubmit={e => {
                            loginAction(e)
                        }}
                    >
                        <div className="login-form-row">
                            <img className='logo' src={logo} alt="boosta-education-logo" />
                        </div>
                        <div className="login-form-row">
                            <div className="row-label">Логін</div>
                            <input
                                required
                                type="text"
                                className={!incorrectAuth ? 'row-input' : 'row-input row-input-error'}
                                value={username}
                                onChange={e => {
                                    setIncorrectAuth(false)
                                    setUsername(e.target.value)
                                }}

                            />
                        </div>
                        <div className="login-form-row">
                            <div className="row-label">Пароль</div>
                            <input
                                required
                                type="password"
                                className={!incorrectAuth ? 'row-input' : 'row-input row-input-error'}
                                value={password}
                                onChange={e => {
                                    setIncorrectAuth(false)
                                    setPassword(e.target.value)
                                }}

                            />
                        </div>
                        <button
                            type='submit'
                            className='btn btn-login'
                        >
                            {isLoading ? <img src={preloader} alt="loading" /> : 'Увійти'}
                        </button>

                        <Link className='login-form-forgot-pass' to='/reset-password/request'>Забули пароль?</Link>


                    </form>
                </div>
            </div>
        )
    } else {
        return <Redirect to='' />
    }

}
