import { createContext, useReducer } from 'react'
import AppReducer from './AppReducer'


let initState = {
    isLogged: false,
    user: null,
    token: null,
    user_theme: 'light'
}


// create context
export const GlobalContext = createContext(initState)


// create provider
export function GlobalProvider({ children }){
    const [ globalState, dispatch ] = useReducer(AppReducer, initState)


    function setUser(value){
        dispatch({
            type: 'SET_USER',
            payload: value
        })
    }

    function setToken(value){
        dispatch({
            type: 'SET_TOKEN',
            payload: value
        })
    }

    function setIsLogged(value){
        dispatch({
            type: 'SET_ISLOGGED',
            payload: value
        })
    }

    function setTheme(value){
        dispatch({
            type: 'SET_THEME',
            payload: value
        })
    }

    return (
        <GlobalContext.Provider value={{
            globalState,
            setUser,
            setToken,
            setIsLogged,
            setTheme
        }}>
            {children}
        </GlobalContext.Provider>
    )
}