import { useEffect, useState } from 'react'
import { formateDateTime } from '../../../../helpers/functions'
import preloader from '../../../../images/preloader.svg'


export default function LessonTypePractice({lesson, isLessonDeadline, hasPracticeAnswer}) {

    const [ lessonData, setLessonData ] = useState(null)

    useEffect(()=>{
        if(lesson.type_practice_question.length !== 0){
            setLessonData(lesson.type_practice_question[0])
        }
    // eslint-disable-next-line
    }, [])


    if(lessonData){
        return (
            <div className='LessonTypePractice'>
                <div className="lesson-main">

                    {hasPracticeAnswer !== null ? 
                        <>
                            {!hasPracticeAnswer ? 
                                (
                                    !isLessonDeadline && !hasPracticeAnswer ? 
                                        <div className="lesson-deadline-notice">
                                            Відправка була достуна до {formateDateTime(lesson.deadline_to)}
                                        </div>
                                    :   
                                    (lesson.deadline_to && !hasPracticeAnswer ?     
                                        <div className="lesson-deadline-notice">
                                            Виконання завдання доступно до {formateDateTime(lesson.deadline_to)}
                                        </div>
                                    :null)
                                )
                            :null}

                            <div className={`lesson-text ${!isLessonDeadline && !hasPracticeAnswer ? 'disabled' :''}`} dangerouslySetInnerHTML={{ __html: lessonData.question_text }}></div>

                            {lessonData.question_important_notice ? 
                                <div className={`lesson-important-notice ${!isLessonDeadline && !hasPracticeAnswer ? 'disabled' :''}`}>
                                    <div className="important-notice-heading">Важливо:</div>
                                    <div className="important-notice-text" dangerouslySetInnerHTML={{ __html: lessonData.question_important_notice }}></div>
                                </div>
                            :null}

                        </>
                    :
                        <img className='preloader preloader-fw black' src={preloader} alt='loading'/>
                    }
                </div>
            </div>
        )
    } else return null

    
}
