export function saveUserLogin(user, token) {

    if (user.username && token) {
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('token', JSON.stringify(token));

    } else {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
    }
}

export function saveUserTheme(theme) {
    localStorage.setItem('app_theme', JSON.stringify(theme));
}

export function getLessonTypeLabel(lesson_type_id) {
    switch (lesson_type_id) {
        case 1:
            return 'Лекція'

        case 2:
            return 'Практичне завдання'

        case 3:
            return 'Q&A'

        case 4:
            return 'Тест'

        case 5:
            return 'Трансляція'

        case 6:
            return 'Вебінар'

        default:
            break;
    }
}

export function getLessonMaterialType(material_type) {
    switch (material_type) {
        case 1:
            return 'pdf'
        case 2:
            return 'doc'

        default:
            return 'file';
    }
}

export function isLessonAvailable(available_from) {
    if (available_from !== null) {

        let now = new Date()
        available_from = new Date(available_from)

        now = now.getTime()
        available_from = available_from.getTime()

        if (now >= available_from) {
            return true
        }

        return false
    }

    return true
}

export function isLessonMeetDealine(deadline_to) {
    if (deadline_to !== null) {

        let now = new Date()
        deadline_to = new Date(deadline_to)

        now = now.getTime()
        deadline_to = deadline_to.getTime()

        if (now <= deadline_to) {
            return true
        }

        return false
    }

    return true
}

export function hasAccessLesson(passPercentage, userPercentage) {

    if (isNaN(passPercentage)) {
        return true
    }

    if (userPercentage >= passPercentage) {
        return true
    }

    return false
}

export function formateDateTime(dateTime) {
    if (dateTime !== null) {

        dateTime = new Date(dateTime)
        let day = dateTime.getDate()
        let month = dateTime.getMonth() + 1
        let year = dateTime.getFullYear()
        let hours = dateTime.getHours()
        let minutes = dateTime.getMinutes()

        day = `${day}`.length < 2 ? `0${day}` : day
        month = `${month}`.length < 2 ? `0${month}` : month
        minutes = `${minutes}`.length < 2 ? `0${minutes}` : minutes

        return `${day}.${month}.${year} ${hours}:${minutes}`
    }

    return null
}

export function formateDate(date) {
    if (date !== null) {

        date = new Date(date)
        let day = date.getDate()
        let month = date.getMonth() + 1
        let year = date.getFullYear()

        day = `${day}`.length < 2 ? `0${day}` : day
        month = `${month}`.length < 2 ? `0${month}` : month

        return `${day}.${month}.${year}`
    }

    return null
}

export function formateTime(date) {
    if (date !== null) {

        date = new Date(date)
        let hours = date.getHours()
        let minutes = date.getMinutes()

        minutes = `${minutes}`.length < 2 ? `0${minutes}` : minutes

        return `${hours}:${minutes}`
    }

    return null
}

export function formateDateMonthText(date) {
    if (date !== null) {

        date = new Date(date)

        const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"
        ];


        let month = date.getMonth()
        let year = date.getFullYear()

        month = monthNames[month]

        return `${month} ${year}`
    }

    return null
}

export function formateFullDateUA(date) {
    if (date !== null) {

        date = new Date(date)

        const monthNames = ["січ.", "лют.", "бер.", "квіт.", "трав.", "черв.", "лип.", "серп.", "вер.", "жовт.", "лист.", "груд."];


        let day = date.getDate()
        let month = date.getMonth()
        let year = date.getFullYear()

        month = monthNames[month]

        return `${day} ${month} ${year}`
    }

    return null
}

export function dateTimeCoutdownMarkup(dateTime) {

    if (dateTime !== null) {

        let now = new Date()
        let available_from = new Date(dateTime)

        now = now.getTime()
        available_from = available_from.getTime()
        let delta = available_from - now

        if (delta < 0) {
            return null
        }


        let days = Math.floor(delta / (1000 * 60 * 60 * 24));
        let hours = Math.floor((delta % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        let minutes = Math.floor((delta % (1000 * 60 * 60)) / (1000 * 60));
        let seconds = Math.floor((delta % (1000 * 60)) / 1000);

        days = `${days}`.length < 2 ? `0${days}` : days
        hours = `${hours}`.length < 2 ? `0${hours}` : hours
        minutes = `${minutes}`.length < 2 ? `0${minutes}` : minutes
        seconds = `${seconds}`.length < 2 ? `0${seconds}` : seconds



        return (
            <div className="cutdown-timer">
                <div className="cutdowm-cell-wrap">
                    <div className="cutdown-cell-value">
                        {days}
                    </div>
                    <div className="cutdown-cell-label">
                        дн
                    </div>
                </div>
                <div className="cutdowm-cell-wrap">
                    <div className="cutdown-cell-value">
                        {hours}
                    </div>
                    <div className="cutdown-cell-label">
                        год
                    </div>
                </div>
                <div className="cutdowm-cell-wrap">
                    <div className="cutdown-cell-value">
                        {minutes}
                    </div>
                    <div className="cutdown-cell-label">
                        хв
                    </div>
                </div>
                <div className="cutdowm-cell-wrap">
                    <div className="cutdown-cell-value">
                        {seconds}
                    </div>
                    <div className="cutdown-cell-label">
                        сек
                    </div>
                </div>
            </div>
        )

    }
}

export function formateTestTime(seconds) {

    let hours = Math.floor((seconds % (60 * 60 * 24)) / (60 * 60));
    let min = Math.floor((seconds % (60 * 60)) / 60)
    let sec = Math.floor((seconds % (60)))

    if (hours) {
        hours = `${hours}`.length < 2 ? `0${hours}` : hours
    }

    min = `${min}`.length < 2 ? `0${min}` : min
    sec = `${sec}`.length < 2 ? `0${sec}` : sec

    if (hours) {
        return `${hours}:${min}:${sec}`
    }

    return `${min}:${sec}`

}


export function seoTitle(title) {
    document.title = title ? `${title} — Boosta Education` : 'Boosta Education';
}

export function arrayEquals(a, b) {
    if (a.length !== b.length) return false;

    const uniqueValues = new Set([...a, ...b]);
    for (const v of uniqueValues) {
        const aCount = a.filter(e => e === v).length;
        const bCount = b.filter(e => e === v).length;
        if (aCount !== bCount) return false;
    }
    return true;
}

export function isJson(str) {
    try {
        const obj = JSON.parse(str);
        if (obj && typeof obj === `object`) {
            return true;
        }
    } catch (err) {
        return false;
    }
    return false;
}


export function transliterate(word) {

    var answer = "";

    let A = [];
    A["Ё"] = "YO"; A["Й"] = "I"; A["Ц"] = "TS"; A["У"] = "U"; A["К"] = "K"; A["Е"] = "E"; A["Н"] = "N"; A["Г"] = "G"; A["Ш"] = "SH"; A["Щ"] = "SCH"; A["З"] = "Z"; A["Х"] = "H"; A["Ъ"] = "'"; A["ё"] = "yo"; A["й"] = "i"; A["ц"] = "ts"; A["у"] = "u"; A["к"] = "k"; A["е"] = "e"; A["н"] = "n"; A["г"] = "g"; A["ш"] = "sh"; A["щ"] = "sch"; A["з"] = "z"; A["х"] = "h"; A["ъ"] = "'"; A["Ф"] = "F"; A["Ы"] = "I"; A["В"] = "V"; A["А"] = "A"; A["П"] = "P"; A["Р"] = "R"; A["О"] = "O"; A["Л"] = "L"; A["Д"] = "D"; A["Ж"] = "ZH"; A["Э"] = "E"; A["ф"] = "f"; A["ы"] = "i"; A["в"] = "v"; A["а"] = "a"; A["п"] = "p"; A["р"] = "r"; A["о"] = "o"; A["л"] = "l"; A["д"] = "d"; A["ж"] = "zh"; A["э"] = "e"; A["Я"] = "YA"; A["Ч"] = "CH"; A["С"] = "S"; A["М"] = "M"; A["И"] = "I"; A["Т"] = "T"; A["Ь"] = "'"; A["Б"] = "B"; A["Ю"] = "YU"; A["я"] = "ya"; A["ч"] = "ch"; A["с"] = "s"; A["м"] = "m"; A["и"] = "i"; A["т"] = "t"; A["ь"] = "'"; A["б"] = "b"; A["ю"] = "yu"; A["І"] = "I"; A["і"] = "i"; A["ї"] = "yi"; A["Ї"] = "YI"; A["ґ"] = "g"; A["Ґ"] = "G"; A["&"] = "&"; A["#"] = "#"; A["@"] = "@"; A["%"] = "%"; A["."] = "."; A[","] = ","; A[":"] = ":"; A[";"] = ";"; A["-"] = "-"; A["!"] = "!";

    for (let i in word) {

        if (word[i] === ' ') {
            answer += ' ';
        }
        else if (/^[a-z]+$/i.test(word[i])) {
            answer += word[i];
        }
        else if (/^\d*(\.\d+)?$/.test(word[i])) {
            answer += word[i];
        }
        else if (A[word[i]] === undefined) {
            answer += '?';
        }
        else {
            answer += A[word[i]];
        }

    }
    return answer;
}

export function shuffleArray(arr) {
    const newArr = arr.slice()
    for (let i = newArr.length - 1; i > 0; i--) {
        const rand = Math.floor(Math.random() * (i + 1));
        [newArr[i], newArr[rand]] = [newArr[rand], newArr[i]];
    }
    return newArr
}


export function placeholderText() {
    return 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam eaque ipsa, quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt, explicabo. Nemo enim ipsam voluptatem, quia voluptas sit, aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos, qui ratione voluptatem sequi nesciunt, neque porro quisquam est.'
}


export function timeDifference(date) {
    const time = new Date(date).getTime()
    const now = new Date().getTime()
    return time - now;
}

export function newlineText(string) {
    if (string.includes('\r\n')) {
        return string.split('\r\n').map((str, i) => <p key={i}>{str}</p>);
    }

    if (string.includes('\n')) {
        return string.split('\n').map((str, i) => <p key={i}>{str}</p>);
    }

    return <p>{string}</p>;

}