import { useState } from 'react'
import preloader from '../../../../../images/preloader.svg'


export default function LessonTypeWebinarChat({lesson}) {

    const [ isLoading, setIsLoading ] = useState(true)

    const lesson_video_url = 
        'type_webinar' in lesson &&
        lesson['type_webinar'].length > 0 &&
        lesson['type_webinar'][0]['video_embed_url']
    ?
        lesson['type_webinar'][0]['video_embed_url']
    :
        null;


  return (
    <>
        {lesson_video_url ?     
            <div className='LessonTypeWebinarChat'>
                <div className="chat-title">
                    Чат трансляції
                </div>
                <div className="chat-main">

                    {isLoading ? <img className='preloader preloader-fw black' src={preloader} alt='loading'/> : null}

                    <iframe
                        title='webinar-title'
                        className={isLoading ? 'hidden' : ''}
                        onLoad={()=>setIsLoading(false)}
                        src={lesson_video_url.slice(-1) !== '/' ? `${lesson_video_url}/chat/` : `${lesson_video_url}chat/` } 
                        width="100%" 
                        height="100%" 
                        frameBorder="0"
                    ></iframe>
                </div>
            </div>
        :null}
    </>

  )
}
