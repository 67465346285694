import { useEffect, useState, useContext } from 'react'
import { useHistory } from 'react-router';
import { saveUserLogin, formateTestTime, isJson, formateDateTime } from '../../../../../helpers/functions';
import { GlobalContext } from '../../../../../helpers/context/GlobalState'
import preloader from '../../../../../images/preloader.svg'
import axios from 'axios';
import TestQuestion from './TestQuestion';
import TestResponse from './TestResponse';
import TestStartView from './TestStartView';

export default function LessonTypeTest({ lesson, isLessonDeadline, setShowLessonsNavigation }) {

    const { push } = useHistory();

    const { globalState, setUser, setToken, setIsLogged } = useContext(GlobalContext);
    const { token } = globalState

    const [testStartView, setTestStartView] = useState(true)
    const [lessonData, setLessonData] = useState(null)
    const [activeQuestionIndex, setActiveQuestionIndex] = useState(0)
    const [userAnswers, setUserAnswers] = useState([])
    const [testRespond, setTestRespond] = useState(null)
    const [postingTestRespond, setPostingTestRespond] = useState(false)
    const [fetchingTestRespond, setFetchingTestRespond] = useState(false)
    const [testDuration, setTestDuration] = useState(0)
    const [testTimeCounter, setTestTimeCounter] = useState(0)


    useEffect(() => {
        if (lesson.type_test.length !== 0 && lesson.type_test[0].test_questions.length !== 0) {

            let testDuration = lesson.type_test[0].test_questions.map(question => question.question_time).reduce((sum, a) => sum + a, 0)
            let testData = getLocalStorageTestData(lesson.type_test[0].id)

            clearOldActiveTestData()
            setShowLessonsNavigation(true)

            setLessonData(lesson.type_test[0])
            setTestDuration(testDuration)

            if (testData && testData.length !== 0 && testData[0]['testStarted']) {
                let now = new Date().getTime()
                let testStarted = testData[0]['testStarted']
                let testDeadline = testStarted + (testDuration * 60000)
                let timeLeft = testDeadline - now

                if (timeLeft < 0) {
                    if (testData && testData.length !== 0 && testData[0]['questions']) {
                        submitTest(testData[0]['questions'])
                    } else {
                        submitTest(generateInitAnswers(lesson.type_test[0]))
                    }
                } else {
                    setTestTimeCounter(timeLeft / 1000)
                }

                setTestStartView(false)
                setShowLessonsNavigation(false)

            } else {
                setTestTimeCounter(testDuration * 60)
                fetchTestRespond(lesson.id)
            }


            if (testData && testData.length !== 0 && testData[0]['questions']) {
                setUserAnswers(testData[0]['questions'])
            } else {
                setUserAnswers(generateInitAnswers(lesson.type_test[0]))
            }


        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        let testCounter = null

        if (testTimeCounter <= 0) {
            if (lessonData && !testRespond && !postingTestRespond && !fetchingTestRespond) {
                submitTest(userAnswers)
            }
        } else {
            if (!testRespond) {
                testCounter = setTimeout(() => {
                    if (!testStartView) {
                        setTestTimeCounter(oldCounter => oldCounter - 1)
                    }
                }, 1000)
            }
        }

        return () => clearTimeout(testCounter)

        // eslint-disable-next-line
    }, [testTimeCounter, testStartView, testRespond])



    const generateInitAnswers = (lessonTypeTest) => {
        let initAnswers = lessonTypeTest.test_questions.map((question) => {

            return (
                {
                    'question_id': question['id'],
                    'question_text': question['question_text'].replace(/(<([^>]+)>)/gi, ""),
                    'user_answers': []
                }

            )
        })
        return initAnswers
    }


    const fetchTestRespond = (lesson_id) => {

        setFetchingTestRespond(true)
        setShowLessonsNavigation(true)

        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_PATH}/api/test-responds/?lesson_id=${lesson_id}`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`
            },
        })
            .then(response => {
                if (response.status === 200) {
                    if (Array.isArray(response.data) && response.data.length !== 0) {
                        setTestRespond(response.data[0])
                    }
                    setFetchingTestRespond(false)
                }


            })
            .catch(error => {
                console.log(error)

                if (error.response && error.response.status === 401) {
                    setUser(null)
                    setToken(null)
                    saveUserLogin(false)
                    setIsLogged(false)
                    push('/login')
                }
            });
    }

    const postTestRespond = (testID, testData) => {

        setPostingTestRespond(true)

        let data = {
            "lesson_type_test": {
                "id": testID,
                "test_questions": []
            },
            "respond_data": JSON.stringify(testData)
        }


        axios({
            method: "post",
            url: `${process.env.REACT_APP_API_PATH}/api/test-responds/`,
            data: data,
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`
            },
        })
            .then(response => {
                if (response.status === 201) {
                    setPostingTestRespond(false)
                    fetchTestRespond(lesson.id)
                    removeLocalStorageTestData(testID)
                }
            })
            .catch(error => {
                console.log(error);
            });

    }


    const handleStoreAnswers = (questionIndex, answers) => {

        let updatedUserAnswers = [...userAnswers]

        updatedUserAnswers[questionIndex]['user_answers'] = answers
        setUserAnswers(updatedUserAnswers)


        setLocalStorageTestData({
            'testStarted': new Date().getTime(),
            'id': lessonData.id,
            'questions': updatedUserAnswers
        })

    }

    const submitTest = (answers) => {

        let _userAnswersData = [...answers]

        let testQuestions = { "questions": _userAnswersData }

        // console.log(testQuestions)
        postTestRespond(lesson.type_test[0].id, testQuestions)
    }

    const getLocalStorageTestData = (testID) => {
        let data = localStorage.getItem('activeTestsData')
        if (data && isJson(data)) {

            data = JSON.parse(data)

            if (testID) {
                return data.filter(test => test.id === testID)
            }
            return data
        }
        return false
    }

    const setLocalStorageTestData = (data) => {
        let storedData = getLocalStorageTestData()

        if (storedData) {
            if (Array.isArray(storedData)) {
                let currentData = storedData.filter(test => test.id === data.id)

                if (currentData.length !== 0) {
                    currentData = currentData[0]
                    storedData = storedData.filter(test => test.id !== data.id)
                    currentData['questions'] = data['questions']
                    storedData.push(currentData)
                } else {
                    storedData.push(data)
                }
                localStorage.setItem('activeTestsData', JSON.stringify(storedData))
            }
        } else {
            localStorage.setItem('activeTestsData', JSON.stringify([data]))
        }
    }

    const removeLocalStorageTestData = (testID) => {
        let storedData = getLocalStorageTestData()

        if (storedData && Array.isArray(storedData)) {
            let updatedData = storedData.filter(test => test.id !== testID)
            localStorage.setItem('activeTestsData', JSON.stringify(updatedData))
            return true
        }

        return false
    }

    const clearOldActiveTestData = () => {
        // clear data from old test data functionality for users that still has it in local storage

        let data = localStorage.getItem('activeTestsData')
        if (data && isJson(data)) {
            data = JSON.parse(data)
        }

        if (
            data &&
            data.length !== 0 &&
            'questions' in data[0] &&
            'correct_answers' in data[0]['questions'][0]
        ) {
            localStorage.setItem('activeTestsData', JSON.stringify([]))
        }
    }



    if (lessonData) {
        let questionsTotal = lessonData.test_questions.length

        return (
            <div className='LessonTypeTest'>
                <div className="lesson-main">


                    {!fetchingTestRespond && !testRespond && testStartView ?

                        <>

                            {lesson.deadline_to ?
                                (!isLessonDeadline ?

                                    <div className="lesson-deadline-notice">
                                        Тест був достуний до {formateDateTime(lesson.deadline_to)}
                                    </div>

                                    :
                                    <div className="lesson-deadline-notice">
                                        Тест буде доступний до {formateDateTime(lesson.deadline_to)}
                                    </div>

                                )
                                : null}




                            <TestStartView
                                lessonData={lessonData}
                                questionsTotal={questionsTotal}
                                setTestStartView={setTestStartView}
                                testDuration={testDuration}
                                setLocalStorageTestData={setLocalStorageTestData}
                                userAnswers={userAnswers}
                                setShowLessonsNavigation={setShowLessonsNavigation}
                                isLessonDeadline={isLessonDeadline}
                            />
                        </>

                        : null}


                    {fetchingTestRespond ?
                        <img className='preloader preloader-fw black' src={preloader} alt='loading' />
                        : null}

                    {!fetchingTestRespond && testRespond ?
                        <TestResponse
                            response={testRespond}
                        />
                        : null}

                    {!testStartView && !fetchingTestRespond && !testRespond ?
                        <div className="lesson-test">
                            <div className={`test-counter ${testTimeCounter <= 60 ? 'counter-warn' : ''}`}>
                                {formateTestTime(testTimeCounter)}
                            </div>
                            {lessonData.test_questions.map((question, index) => {
                                if (activeQuestionIndex === index) {
                                    return (
                                        <TestQuestion
                                            key={index}
                                            index={index}
                                            question={question}
                                            questionsTotal={questionsTotal}
                                            handleStoreAnswers={handleStoreAnswers}
                                            selectedAnswers={userAnswers[index].user_answers}
                                            lesson={lesson}
                                        />
                                    )
                                } else {
                                    return null
                                }

                            })}

                            <div className="test-controls">

                                {
                                    postingTestRespond ?
                                        <img className='preloader preloader-fw black' src={preloader} alt='loading' />
                                        :

                                        <div className="test-controls-main">
                                            <div
                                                className={activeQuestionIndex > 0 ? 'test-control test-control-prev' : 'test-control test-control-prev test-control-disabled'}
                                                onClick={() => {
                                                    if (activeQuestionIndex > 0) {
                                                        setActiveQuestionIndex(activeQuestionIndex - 1)
                                                    }
                                                }}
                                            >
                                                попереднє
                                            </div>

                                            {activeQuestionIndex + 1 !== questionsTotal ?
                                                <div
                                                    className="test-control test-control-next"
                                                    onClick={() => {
                                                        setActiveQuestionIndex(activeQuestionIndex + 1)
                                                    }}
                                                >
                                                    наступне
                                                </div>
                                                :
                                                <div
                                                    className={`test-control test-control-submit btn`}
                                                    onClick={() => submitTest(userAnswers)}
                                                >
                                                    завершити
                                                </div>
                                            }

                                        </div>
                                }

                            </div>
                        </div>
                        : null}

                </div>
            </div>
        )
    } else return null


}
