import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import { transliterate, formateDateMonthText } from '../functions'
import QRCode from 'qrcode'
import pdf_2 from '../../certificate-templates/boosta-certificate-template-2.pdf'


const generateQR = async text => {
    try {
        let img = await QRCode.toDataURL(text, {type: "png", width: 500})
        return img
    } catch (err) {
      console.error(err)
    }
}


export async function downloadPDFTemplate2 (certificateData){

    const qr = await generateQR(`https://education.boosta.biz/student-certificate/${certificateData.uuid}`)
    
    const file = pdf_2
    const existingPdfBytes = await fetch(file).then(res => res.arrayBuffer())

    const pdfDoc = await PDFDocument.load(existingPdfBytes)
    const font = await pdfDoc.embedFont(StandardFonts.HelveticaBold)
    const fontLight = await pdfDoc.embedFont(StandardFonts.Helvetica)

    const pages = pdfDoc.getPages()
    const firstPage = pages[0]
    const { width } = firstPage.getSize()




    // draw course title
    const courseTitle = transliterate(certificateData.course_name.trim()).toUpperCase();
    const courseTitleFontSize = 16;

    const courseTitleTextWidth = font.widthOfTextAtSize(courseTitle, courseTitleFontSize);

    firstPage.drawText(courseTitle, {
        x: width/2 - courseTitleTextWidth/2,
        y: 200,
        size: courseTitleFontSize,
        font: font,
        color: rgb(0, 0, 0)
    })

    

    // draw student name
    const studentName = transliterate(`${certificateData.user_first_name.trim()} ${certificateData.user_last_name.trim()}`);
    const studentNameFontSize = 27;
    const studentNameTextWidth = font.widthOfTextAtSize(studentName, studentNameFontSize);

    firstPage.drawText(studentName, {
        x: width/2 - studentNameTextWidth/2,
        y: 150,
        size: studentNameFontSize,
        font: font,
        color: rgb(0, 0, 0)
    })


    // draw certificate date
    const CertificateDate = formateDateMonthText(certificateData.generated_date);
    const CertificateDateFontSize = 9;
    const CertificateDateTextWidth = fontLight.widthOfTextAtSize(CertificateDate, CertificateDateFontSize);

    firstPage.drawText(CertificateDate, {
        x: width/2 - CertificateDateTextWidth/2,
        y: 110,
        size: CertificateDateFontSize,
        font: fontLight,
        color: rgb(0, 0, 0)
    })




    // draw image
    const pngImageBytes = await fetch(qr).then((res) => res.arrayBuffer());
    const pngImage = await pdfDoc.embedPng(pngImageBytes);
    const pngDims = pngImage.scale(0.15);

    firstPage.drawImage(pngImage, {
        x: width / 2 - pngDims.width / 2,
        y: 10,
        width: pngDims.width,
        height: pngDims.height,
    });

    const pdfBytes = await pdfDoc.save()

    const bytes  = new Uint8Array( pdfBytes ); 
    const blob   = new Blob( [ bytes ], { type: "application/pdf" } );
    const docUrl = URL.createObjectURL( blob );

    const link = document.createElement('a');
    link.href = docUrl;
    const studentNameSlug = studentName.replace(' ', '-').toLowerCase();
    link.download = `boosta-education-certificate-${studentNameSlug}-${certificateData.uuid}.pdf`;
    link.click();

};