import { React, useContext, useEffect, useState } from 'react'
import { useLocation, Link } from 'react-router-dom'
import { GlobalContext } from '../../../helpers/context/GlobalState';
import { saveUserTheme } from '../../../helpers/functions';
import logoBlack from '../../../images/boosta-logo-black.svg'
import logoWhite from '../../../images/boosta-logo-white.svg'
import logoSmall from '../../../images/boosta-logo-small.svg'

const getScreenWidth = () => window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

export default function Header() {
    const { pathname } = useLocation();
    const { globalState, setTheme } = useContext(GlobalContext)
    const { user, user_theme } = globalState

    const [ screenWidth, setScreenWidth ] = useState(null) 

    useEffect(()=>{

        const resizeListener = () => {
            setScreenWidth(getScreenWidth())
        };

        resizeListener()

        window.addEventListener('resize', resizeListener);
    
        return () => {
            window.removeEventListener('resize', resizeListener);
        }

    // eslint-disable-next-line
    }, [])


    if(
        pathname === '/login' ||
        pathname === '/reset-password/request/' ||
        pathname === '/reset-password/request' ||
        pathname === '/reset-password/change/' ||
        pathname === '/reset-password/change' ||
        !user
    ){
        return null
    } else {
        return (
            <div className='Header'>
                <div className="container">
                    <div className="logo">
                        {screenWidth ? 
                            <Link to='/'>
                                {screenWidth < 768 ? 
                                    <img src={logoSmall} alt="boosta-education-logo" />
                                :
                                    <img src={user_theme === 'dark' ? logoWhite : logoBlack} alt="boosta-education-logo" />
                                }
                            </Link>
                        :null}
                    </div>
                    <div className="header-actions">
                        
                        <div 
                            className={`theme-switch ${user_theme === 'dark' ? '' : 'active'}`}
                            onClick={()=>{
                                setTheme(user_theme === 'dark' ? 'light' : 'dark')
                                saveUserTheme(user_theme === 'dark' ? 'light' : 'dark')
                            }}
                        
                        >
                            <div className="switch-inner">
                                <div className="dark label">dark</div>
                                <div className="switcher-circle"></div>
                                <div className="light label">light</div>
                            </div>
                        </div>

                        <Link className='btn account-btn' to='/account'>
                            Мій кабінет
                        </Link>

                    </div>
                </div>
            </div>
        )
    }
    
}
