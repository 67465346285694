import { React, useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { GlobalContext } from '../../../../helpers/context/GlobalState';
import preloader from '../../../../images/preloader.svg'
import axios from 'axios';
import { saveUserLogin } from '../../../../helpers/functions';

export default function BlockExit() {
    const { push } = useHistory();
    const { globalState, setUser, setToken, setIsLogged } = useContext(GlobalContext)
    const { token } = globalState

    const [isLoading, setIsLoading] = useState(false)


    const logOut = () => {

        setIsLoading(true)

        axios({
            method: "post",
            url: `${process.env.REACT_APP_API_PATH}/api/auth/logout`,
            headers: { 
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`
            },
        })
        .then(() => {
            setIsLoading(false)
            setUser(null)
            setToken(null)
            saveUserLogin(false)
            setIsLogged(false)
            push('/login')
        })
        .catch(error => {
            console.log(error);
            setIsLoading(false)
            setUser(null)
            setToken(null)
            saveUserLogin(false)
            setIsLogged(false)
            push('/login')
        });

    }

    
    return (
        <div className='BlockExit'>
            <div className="block-content">
                <div 
                    className="btn user-logout"
                    onClick={()=>{
                        logOut()
                    }}
                >
                    {isLoading ? <img src={preloader} alt='loading' /> : 'Вийти'}
                </div>
            </div>
        </div>
    )
    
}
