import { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import { Link } from 'react-router-dom'
import { formateTime } from '../../../../helpers/functions';

export default function CourseCalendar({ modules, course_id, calendar_url }) {

    const [selectedDate, setSelectedDate] = useState(new Date());
    const [filteredLessons, setFilteredLessons] = useState([])

    const livestreams = modules.map(module => 'lessons' in module && module.lessons.filter(lesson => [5, 6].includes(lesson.lesson_type))).flat()

    console.log(livestreams);


    useEffect(() => {

        getSelectedDateLessons(selectedDate)

        // eslint-disable-next-line
    }, [])

    const getCalendarCellClass = (date) => {
        let _cssClass = false

        livestreams.forEach(livestream => {

            const { lesson_type_livestream_starts } = livestream

            if(livestream.lesson_type === 6) {
                if (
                    date.getDay() === new Date(livestream.lesson_type_webinar_starts).getDay() &&
                    date.getMonth() === new Date(livestream.lesson_type_webinar_starts).getMonth() &&
                    date.getDate() === new Date(livestream.lesson_type_webinar_starts).getDate()
                ) {
                    _cssClass = 'has-lesson'
                }
            } else {

                if (
                    date.getDay() === new Date(lesson_type_livestream_starts).getDay() &&
                    date.getMonth() === new Date(lesson_type_livestream_starts).getMonth() &&
                    date.getDate() === new Date(lesson_type_livestream_starts).getDate()
                ) {
                    _cssClass = 'has-lesson'
                }
            }


        });


        return _cssClass
    }

    const getSelectedDateLessons = (date) => {

        let lessons = livestreams.filter(livestream => {

            const { lesson_type_webinar_starts, lesson_type_livestream_starts } = livestream

            if (
                (date.getDay() === new Date(lesson_type_webinar_starts).getDay() &&
                date.getMonth() === new Date(lesson_type_webinar_starts).getMonth() &&
                date.getDate() === new Date(lesson_type_webinar_starts).getDate()) ||
                (date.getDay() === new Date(lesson_type_livestream_starts).getDay() &&
                date.getMonth() === new Date(lesson_type_livestream_starts).getMonth() &&
                date.getDate() === new Date(lesson_type_livestream_starts).getDate())
            ) {
                return true
            }

            return false
        });

        lessons = lessons.sort((a, b) => Date.parse(a.lesson_type_livestream_starts) - Date.parse(b.lesson_type_livestream_starts))
        setFilteredLessons(lessons)
    }

    if (livestreams.length === 0) {
        return null;
    }

    return (
        <div className='CourseCalendar'>
            <div className="course-calendar-heading">
                <div className="calendar-title">Календар Трансляцій</div>
            </div>

            <div className="course-calendar-main">
                <Calendar
                    onChange={e => {
                        setSelectedDate(e)
                        getSelectedDateLessons(e)
                    }}
                    value={selectedDate}
                    locale="uk"
                    showNeighboringMonth={false}
                    view='month'
                    nextLabel={false}
                    prevLabel={false}
                    tileClassName={({ date }) => getCalendarCellClass(date)}
                />

                {filteredLessons.length > 0 ?
                    <div className="calendar-lessons-main">
                        <div className="calendar-lessons-list">
                            {filteredLessons.map((lesson, index) => {
                                return (
                                    <div
                                        className="lesson-item"
                                        key={index}
                                    >
                                        <div className="lesson-time">

                                            {lesson.lesson_type === 6 ? formateTime(lesson.lesson_type_webinar_starts) : formateTime(lesson.lesson_type_livestream_starts) }
                                        </div>
                                        <Link className="lesson-name" to={`/course/${course_id}/${lesson.id}`}>
                                            {lesson.lesson_name}
                                        </Link>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    : null}


                    {calendar_url ?
                     <div className="calendar-lessons-link">
                         <a href={`${calendar_url}`} rel="noreferrer" target="_blank">
                            Додати курс в свій "Google Calendar"
                         </a>
                     </div>
                     : null}

            </div>



        </div>
    )
}
