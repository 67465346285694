import { useContext, useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import preloader from '../../images/preloader.svg'
import axios from 'axios'
import { GlobalContext } from '../../helpers/context/GlobalState'
import { seoTitle, formateDate, saveUserLogin } from '../../helpers/functions'
import NotFoundContent from '../parts/layout/NotFound/NotFoundContent';
import { downloadPDFTemplate1 } from '../../helpers/certificate-generators/template_1';
import { downloadPDFTemplate2 } from '../../helpers/certificate-generators/template_2';
import { downloadPDFTemplate3 } from '../../helpers/certificate-generators/template_3';
import BackButton from '../parts/layout/BackButton'
import logoBlack from '../../images/boosta-logo-black.svg'
import logoWhite from '../../images/boosta-logo-white.svg'


export default function Certificate(props) {

    const { push } = useHistory();

    const { globalState, setUser, setIsLogged, setToken } = useContext(GlobalContext)
    const { token, user, user_theme } = globalState

    const [ fetchingCertificate, setFetchingCertificate ] = useState(false)
    const [ fetchEnded, setFetchEnded ] = useState(false)
    const [ certificateData, setCertificateData ] = useState(false)
    const [ generatingPDF, setGeneratingPDF ] = useState(false)

    let { certificate_id } = props.match.params

    useEffect(()=>{

        seoTitle('Сертифікат')

        setFetchingCertificate(true)

        const headers_guest = {
            "Content-Type": "application/json"
        }

        const headers_auth = {
            "Content-Type": "application/json",
            "Authorization": `Token ${token}`
        }

        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_PATH}/api/user-certificate?certificate_id=${certificate_id}`,
            headers: user ? headers_auth : headers_guest,
        })
        .then(response => {
            
            if(response.status === 200){
                
                if('data' in response.data){
                    setCertificateData(response.data.data)
                }
            }

            setFetchingCertificate(false)
            setFetchEnded(true)
        })
        .catch(error => {
            setFetchingCertificate(false)
            setFetchEnded(true)
            
            if(error.response && error.response.status === 401){
                setUser(null)
                setToken(null)
                saveUserLogin(false)
                setIsLogged(false)
                push('/login')
            }
        });


    // eslint-disable-next-line
    }, [])


    const handleDownload = async () => {
        setGeneratingPDF(true)

        if(certificateData.certificate_template === '1'){
            await downloadPDFTemplate1(certificateData)
            setGeneratingPDF(false)
        }
        if(certificateData.certificate_template === '2'){
            await downloadPDFTemplate2(certificateData)
            setGeneratingPDF(false)
        }
        if(certificateData.certificate_template === '3'){
            await downloadPDFTemplate3(certificateData)
            setGeneratingPDF(false)
        }
    }
    
    
    if(fetchEnded && !certificateData){
        return <NotFoundContent />
    }

    if(
        fetchEnded && 
        certificateData &&
        (certificateData.max_practice_points > 0 && certificateData.max_test_points > 0) &&
        certificateData.user_percentage < certificateData.course_percentage
    ){
        return <NotFoundContent />
    }

    return (
        <div className='Certificate page-wrap'>
            <div className="container">


                {user ? 
                    <BackButton link={'/account'} label="Мій профіль" />
                :
                    <div className="certificate-guest-head">
                        <img src={user_theme === 'dark' ? logoWhite : logoBlack} alt="boosta-education-logo" />
                    </div>
                }

                <div className="page-title">
                    Сертифікат
                </div>


                <div className="сertificate-main">

                    {
                        fetchingCertificate ? <img className='preloader preloader-fw black' src={preloader} alt='loading'/> 
                    : 
                        <>
                            {certificateData ? 
                            
                                <>
                                    <div className="certificate-info">
        
                                        <div className="certificate-user-label">
                                            Студент
                                        </div>
                                        <div className="certificate-user">
                                            {`${certificateData.user_first_name} ${certificateData.user_last_name}`}
                                        </div>
        
                                        <div className="certificate-fields">
        
                                            <div className="field-item">
                                                <div className="field-label">
                                                    Курс
                                                </div>
                                                <div className="field-value">
                                                    {certificateData.course_name}
                                                </div>
                                            </div>
                                            <div className="field-item field-half">
                                                <div className="field-label">
                                                    Набрано балів
                                                </div>
                                                <div className="field-value value-points">
                                                    {`${certificateData.user_test_points + certificateData.user_practice_points} / ${certificateData.max_practice_points + certificateData.max_test_points}`}
                                                </div>
                                            </div>
                                            <div className="field-item field-half">
                                                <div className="field-label">
                                                    Дата отримання
                                                </div>
                                                <div className="field-value">
                                                    {formateDate(certificateData.generated_date)}
                                                </div>
                                            </div>
                                        </div>
        
        
                                    </div>
        
        
                                    
                                    
                                    <div className="certificate-actions">
        
                                        {certificateData.show_download ? 
                                        
                                            <button 
                                                className='btn btn-sec download-certificate'
                                                onClick={()=>handleDownload()}
                                                disabled={generatingPDF}
                                            >
                                                {generatingPDF ? <img src={preloader} alt="loading"/> : <span>завантажити</span>}
                                            </button>
                                            
                                        :null}
        
                                    </div>
                                </>
                            
                            :null}
                        </>
                    }
                    
                </div>

            </div>
        </div>
    )
}
