import { useContext, useEffect, useState } from "react";
import { useHistory } from 'react-router';
import axios from "axios";
import { GlobalContext } from "../../../../helpers/context/GlobalState";
import { isJson, saveUserLogin, formateDateTime } from "../../../../helpers/functions";
import preloader from '../../../../images/preloader.svg'


export default function CourseRating({course_id}) {

    const { push } = useHistory();

    const { globalState, setUser, setToken, setIsLogged } = useContext(GlobalContext);
    const { token } = globalState

    const [ isLoading, setIsLoading ] = useState(false)
    const [ ratingData, setRatingData ] = useState(null)
    const [ lastUpdated, setLastUpdated ] = useState(null)
    const [ showAll, setShowAll ] = useState(false)
    const [ ratingItemsLimit ] = useState(10)

    useEffect(() => {

        setIsLoading(true)

        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_PATH}/api/students-rating/?course_id=${course_id}`,
            headers: { 
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`
            },
        })
        .then(response => {

            if(response.status === 200){

                if(response.data){

                    if(isJson(response.data.data)){

                        let data = JSON.parse(response.data.data)
    
                        if(Array.isArray(data) && data.length > 0){
                            setRatingData(data)
                        }

                        if(response.data.created){
                            setLastUpdated(response.data.created)
                        }
                    }

                }
            }

            setIsLoading(false)
        })
        .catch(error => {
            setIsLoading(false)

            if(error.response && error.response.status === 401){
                setUser(null)
                setToken(null)
                saveUserLogin(false)
                setIsLogged(false)
                push('/login')
            }
        });

    // eslint-disable-next-line
    }, [])





    return (
        <div className="CourseRating">
            {isLoading || ratingData ? 
                <>
                    <div className="course-rating-heading">
                        <div className="rating-title">Рейтинг Топ 50 студентів</div>
                        <div className="rating-last-updated">{`Оновлено: ${formateDateTime(lastUpdated)}`}</div>
                    </div>
                </>
                
            :null}

            {isLoading ? <img className='preloader preloader-fw' src={preloader} alt='loading'/> : null}


            {!isLoading && ratingData ? 
                <div className="rating-list-block">
                    <div className="rating-list">
                        {ratingData.map((user, index) => {

                            if(showAll || index < ratingItemsLimit){
                                return (
                                    <div key={index} className="rating-item">
                                        <div className="item-user">
                                            {`${user['user_first_name']} ${user['user_last_name']}`}
                                        </div>
                                        <div className="item-score">
                                            {user['points']}
                                        </div>
                                    </div>
                                )
                            } else {
                                return null
                            }
                        })}
                    </div>
                    
                    {ratingData.length > ratingItemsLimit ? 
                        <div 
                            className="btn rating-show-all"
                            onClick={()=>setShowAll(!showAll)}
                        >
                            {showAll ? 'Сховати' : 'Показати ще'}
                        </div>
                    :null}
                </div>
                
            : null}
        </div>
    );
}
