import { useEffect } from 'react'
import BlockCertificates from '../parts/layout/PersonalCabinet/BlockCertificates';
import BlockExit from '../parts/layout/PersonalCabinet/BlockExit';
import BlockGeneralInfo from '../parts/layout/PersonalCabinet/BlockGeneralInfo'
import { seoTitle } from '../../helpers/functions';
import BackButton from '../parts/layout/BackButton';



export default function PersonalCabinet() {

    useEffect(()=>{
        seoTitle('Мій профіль')
    }, [])


    return (
        <div className='PersonalCabinet page-wrap'>
            <div className="container">

                <BackButton link={'/'} label="Мої курси" />

                <div className="page-title">
                    Мій профіль
                </div>

                <div className="cabinet-content">

                    <div className="cabinet-main">
                        <BlockCertificates />
                    </div>

                    <div className="cabinet-side">
                        <BlockGeneralInfo />
                        <BlockExit />
                    </div>
                </div>
            </div>
        </div>
    )
}
