import ModuleItem from "./ModuleItem";
import { Link } from 'react-router-dom'
import { formateDateTime } from "../../../../helpers/functions";


export default function ModulesList({ courseData, course_id, passQualifyingCourse, setCurrentCourseID }) {

    const isAvailable = 'is_available' in courseData && courseData['is_available'];
    const availableFrom = 'available_from' in courseData && courseData['available_from'];

    return (

        <div className="course-main-block">
            <div className="ModulesList">

                <div className="modules-list-title">учбові модулі</div>

                {isAvailable ?

                    <div className="course-modules-list">
                        {
                            'modules' in courseData &&
                            Array.isArray(courseData.modules) &&
                            courseData.modules.length !== 0 &&
                            courseData.modules.map((module, index) => {

                                return (
                                    <ModuleItem
                                        key={index}
                                        position={index}
                                        module={module}
                                        course_id={course_id}
                                        user_percentage={courseData.user_percentage}
                                    />
                                )

                            })}

                    </div>

                    :

                    <div className="course-closed">
                        {
                            availableFrom ? `Курс буде доступний ${formateDateTime(availableFrom)}` : 'Курс наразі недоступний'
                        }
                    </div>

                }

            </div>

            {passQualifyingCourse ?
                <div className="qualifying-course-pass">
                    <p>Відбірковий курс успішно пройдено!</p>
                    <Link
                        className='btn'
                        to={`/course/${courseData['main_course']['id']}`}
                        onClick={() => {
                            setCurrentCourseID(courseData['main_course']['id'])
                        }}
                    >
                        {courseData['main_course']['course_name']}
                    </Link>
                </div>
                : null}
        </div>


    );

}
