import { useState, useContext } from 'react'
import { Link } from "react-router-dom"
import preloader from '../../../../images/preloader.svg'
import { useHistory } from 'react-router'
import axios from 'axios'
import { GlobalContext } from '../../../../helpers/context/GlobalState'
import { saveUserLogin, transliterate } from '../../../../helpers/functions'




export default function CertificateRow({ course }) {


    const { generated_certificate_uuid, certificate_allowed, has_all_answers, user_percentage, max_points, user_points, course_pass_percent, total_practices, user_completed_practices, total_tests, user_completed_tests, course_id } = course


    const { push } = useHistory();

    const { globalState, setUser, setIsLogged, setToken } = useContext(GlobalContext)
    const { token, user } = globalState

    const [generatingCertificate, setGeneratingCertificate] = useState(false)
    const [generatedCertificateLink, setGeneratedCertificateLink] = useState(null)
    const [showConfirmationPopup, setShowConfirmationPopup] = useState(false)

    let generateCertificate = () => {


        setGeneratingCertificate(true)

        axios({
            method: "post",
            url: `${process.env.REACT_APP_API_PATH}/api/generate-certificate?course_id=${course_id}`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`
            },
        })
            .then(response => {
                setGeneratingCertificate(false)

                if (response.status === 200) {
                    if ('uuid' in response.data) {
                        setGeneratedCertificateLink(response.data.uuid)
                    }
                }
            })
            .catch(error => {
                setGeneratingCertificate(false)

                if (error.response && error.response.status === 401) {
                    setUser(null)
                    setToken(null)
                    saveUserLogin(false)
                    setIsLogged(false)
                    push('/login')
                }
            });

    }

    let lockScroll = switcher => {
        if (switcher) {
            document.querySelector('body').style.overflow = 'hidden';
        } else {
            document.querySelector('body').style.removeProperty('overflow');
        }
    }



    return (
        <>
            {showConfirmationPopup ?
                <div
                    className="certificate-confirm-modal"
                    onClick={e => {
                        if (e.target === e.currentTarget) {
                            setShowConfirmationPopup(false)
                            lockScroll(false)
                        }
                    }}
                >
                    <div className="modal-main">

                        <div
                            className="modal-close"
                            onClick={() => {
                                setShowConfirmationPopup(false)
                                lockScroll(false)
                            }}

                        ></div>

                        <div className="modal-text">
                            Ваше імʼя та прізвище на сертифікаті буде виглядати так: <br />
                            <span>{transliterate(`${user.first_name.trim()} ${user.last_name.trim()}`)}</span>
                        </div>
                        <div className="confirm-modal-actions">
                            <div
                                className="modal-action btn-confirm btn btn-sec"
                                onClick={() => {
                                    setShowConfirmationPopup(false)
                                    lockScroll(false)
                                    generateCertificate()
                                }}
                            >
                                Підтвердити
                            </div>
                            <div
                                className="modal-action btn-cancel"
                                onClick={() => {
                                    setShowConfirmationPopup(false)
                                    lockScroll(false)
                                }}
                            >
                                Відмінити
                            </div>
                        </div>
                    </div>
                </div>
                : null}

            <div className="certificate-item">
                <Link className="certificate-title" to={`course/${course_id}`}>
                    {course.course_title}
                </Link>
                <div className="certificate-points">
                    {`${course.user_points}/${course.max_points} балів ${course.user_percentage !== null ? `(${course.user_percentage}%)` : ''}`}
                </div>
                <div className="certificate-actions">

                    {generatedCertificateLink ?
                        <Link className="certificate-btn btn-open" to={`student-certificate/${generatedCertificateLink}`}>
                            Відкрити
                        </Link>
                        :
                        <>

                            {!generated_certificate_uuid && certificate_allowed ?
                                <div
                                    className="certificate-btn btn-generate"
                                    onClick={() => {
                                        setShowConfirmationPopup(true)
                                        lockScroll(true)
                                    }}
                                >
                                    {generatingCertificate ?
                                        <img className='preloader black' src={preloader} alt='loading' />
                                        :
                                        'згенерувати'
                                    }
                                </div>
                                : null}

                            {generated_certificate_uuid ?
                                <Link className="certificate-btn btn-open" to={`student-certificate/${generated_certificate_uuid}`}>
                                    Відкрити
                                </Link>
                                : null}

                            {!certificate_allowed ?

                                <div className="certificate-unavailable certificate-btn btn-disabled">
                                    <div className="btn-disabled-text">недоступно</div>
                                    <span className='certificate-unavailable-action'></span>

                                    <div className="certificate-unavailable-notice">
                                        {!has_all_answers ?
                                            <>
                                                {user_completed_practices < total_practices && user_completed_tests < total_tests ?

                                                    <p>Не виконані всі практичні та тестові завдання</p>
                                                    :
                                                    <>
                                                        {user_completed_practices < total_practices ?
                                                            <p>Не виконані всі практичні завдання</p>
                                                            : null}
                                                        {user_completed_tests < total_tests ?
                                                            <p>Не виконані всі тестові завдання</p>
                                                            : null}
                                                    </>
                                                }

                                            </>
                                            : null}

                                        {has_all_answers ?

                                            <p>Твій рейтинг {user_percentage}% (набрано {user_points}/{max_points} балів). Прохідний рейтинг для отримання сертифіката - {course_pass_percent}%</p>

                                            : null}
                                    </div>
                                </div>

                                : null}
                        </>
                    }




                </div>
            </div>
        </>
    )
}
