import { Link } from 'react-router-dom'
import { getLessonTypeLabel, isLessonAvailable, hasAccessLesson, formateDateTime, timeDifference } from '../../../../helpers/functions'
import checkMark from "../../../../images/mark-orange.svg"

export default function LessonItem({lesson, course_id, user_percentage}) {
    let isAvailable = isLessonAvailable(lesson.available_from)
    let hasAccess = hasAccessLesson(lesson.limited_access_percentage, user_percentage)
    return (
        <div className="LessonItem">
            <div className="lesson-type">
                <div className={`lesson-type-label type-${lesson.lesson_type} ${!hasAccess || !isAvailable ? 'blocked' : ''}`}>
                    {getLessonTypeLabel(lesson.lesson_type)}
                </div>
            </div>
            <div className="lesson-name">
                
                {isAvailable ?

                    <>

                        {hasAccess ? 

                            <>
                                <Link to={`/course/${course_id}/${lesson.id}`}>
                                    {lesson.lesson_name}
                                </Link>
                                {lesson.user_has_response ?
                                        <img className="lesson-status" src={checkMark} alt="check-mark" width="31" height="29" />
                                    : null }
                            </>
                        :
                            <div className="lesson-disabled">
                                <div className="lesson-title">
                                    {lesson.lesson_name}
                                </div>
                                <div className="lesson-notice">
                                    {`доступно для студентів з рейтингом ${lesson.limited_access_percentage}% та вище (поточний рейтинг - ${user_percentage}%)`}
                                </div>
                            </div>
                        }
                    
                    </>
                :

                    <div className="lesson-disabled">
                        <div className="lesson-title">
                            {lesson.lesson_name}
                        </div>
                        <div className="lesson-notice">
                            доступно починаючи з <span>{formateDateTime(lesson.available_from)}</span>
                        </div>
                    </div>
                    
                }

            </div>

            
            {lesson.lesson_type_livestream_starts && lesson.lesson_type === 5 ?
                <div
                    className={
                            timeDifference(lesson.lesson_type_livestream_starts) < 1_800_000 &&
                            timeDifference(lesson.lesson_type_livestream_starts) > -900_000
                        ?
                            'lesson-livestream-starts accent'
                        :
                            'lesson-livestream-starts'
                    }
                >
                    {`Заплановано на ${formateDateTime(lesson.lesson_type_livestream_starts)}`}
                </div>
            : null }

             {lesson.lesson_type_webinar_starts && lesson.lesson_type === 6 ?
                <div
                    className={
                            timeDifference(lesson.lesson_type_webinar_starts) < 1_800_000 &&
                            timeDifference(lesson.lesson_type_webinar_starts) > -900_000
                        ?
                            'lesson-livestream-starts accent'
                        :
                            'lesson-livestream-starts'
                    }
                >
                    {`Заплановано на ${formateDateTime(lesson.lesson_type_webinar_starts)}`}
                </div>
            : null }
        </div>
    )
}
