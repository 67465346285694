import { Link } from 'react-router-dom';

export default function LessonNavigation( { lesson, scoreFromSubmited, setNavigationClicked, nextLink, prevLink} ) {

	let handleClick = ( e ) => {
		if ( !scoreFromSubmited ) {
			setNavigationClicked( true );

			setTimeout(() => {
				setNavigationClicked( false );
			}, 1000);
		}
	};
	return (
		<div className="lesson-navigation">
			{prevLink ?
				<Link
					to={prevLink}
					className="prev"
				>
					<div className="nav-label">Попереднє</div>
				</Link>
				: null}
			{nextLink ? (
					<>
						{(scoreFromSubmited || lesson.lesson_type !== 1) && (scoreFromSubmited || lesson.lesson_type !== 5) ? (
							<Link
								to={nextLink}
								className="next"
								onClick={handleClick}
							>
								<div className="nav-label">Наступне</div>
							</Link>
						) : (
							<Link
								to="#"
								className="next disabled"
								onClick={handleClick}
							>
								<div className="nav-label">Наступне</div>
							</Link>
						)}
					</>
				)
				: null}
		</div>
	);
}
