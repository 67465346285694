import { getLessonMaterialType } from "../../../../helpers/functions";


export default function LessonMaterials({materials}) {
    return (
        <div className="LessonMaterials">
            <div className="materials-title">
                Матеріали уроку
            </div>

            <div className="materials-list">
                {materials.map((material, index) => {

                    let materialIcon = getLessonMaterialType(material.material_type);

                    
                    return (
                        <div
                            key={index} 
                            className="material-item"
                        >
                            <a 
                                href={material.link}
                                target='_blank'
                                rel="noreferrer"
                                className="material-item-link"
                            >
                                {materialIcon ? 
                                    <img 
                                        className="item-icon" 
                                        src={require(`../../../../images/icon-${materialIcon}.svg`).default} 
                                        alt={`${materialIcon}`}
                                    />
                                :null}
                                <div className="item-title">
                                    {material.title}
                                </div>
                                
                            </a>
                            
                        </div>
                    )
                    
                })}
            </div>
        </div>
    )
}
