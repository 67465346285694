import { Link } from "react-router-dom"

export default function CourseItem({course}) {

    const { id, course_name, course_description, course_type } = course

    return (
        <div className='CourseItem'>
            <Link to={`/course/${id}`}>
                <div className="course-item-content">
                    <div className="course-title">
                        {course_name}
                    </div>
                    <div className="course-description">
                        {course_description}
                    </div>
                    {course_type === 1 ? 
                        <div className="course-type free">free</div>
                    :null}
                </div>
            </Link>           
            
        </div>
    )
}
