import { useEffect, useState, useContext } from 'react'
import { useHistory } from 'react-router';
import { saveUserLogin, isJson, newlineText } from '../../../../helpers/functions';
import { GlobalContext } from '../../../../helpers/context/GlobalState'
import preloader from '../../../../images/preloader.svg'
import axios from 'axios';
import Linkify from 'react-linkify';


export default function LessonTypePracticeAnswer({ lesson, isLessonDeadline, setHasPracticeAnswer }) {

    const { push } = useHistory();

    const { globalState, setUser, setToken, setIsLogged } = useContext(GlobalContext);
    const { token } = globalState

    const [postingAnswer, setPostingAnswer] = useState(false)
    const [lessonData, setLessonData] = useState(null)
    const [answerInput, setAnswerInput] = useState(null)
    const [parcticeAnswer, setPracticeAnswer] = useState(null)
    const [fetchingPracticeAnswer, setFetchingPracticeAnswer] = useState(false)
    const [typingTimeoutID, setTypingTimeoutID] = useState(null)

    useEffect(() => {

        if (lesson.type_practice_question.length !== 0) {
            setLessonData(lesson.type_practice_question[0])

            fetchPracticeAnswer(lesson.id)
        }
        // eslint-disable-next-line
    }, [])


    useEffect(() => {
        const typingTimeout = setTimeout(() => {
            if (answerInput !== null && !postingAnswer) {
                saveUserInput(answerInput)
            }
        }, 2000);

        setTypingTimeoutID(typingTimeout)

        return () => clearTimeout(typingTimeout)

        // eslint-disable-next-line
    }, [answerInput])


    let fetchPracticeAnswer = (lesson_id) => {

        setFetchingPracticeAnswer(true)

        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_PATH}/api/practice-answers/?lesson_id=${lesson_id}`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`
            },
        })
            .then(response => {
                if (response.status === 200) {
                    if (Array.isArray(response.data) && response.data.length !== 0) {
                        setPracticeAnswer(response.data[0])
                        setHasPracticeAnswer(true)
                    } else {
                        setPracticeAnswer(false)
                        setHasPracticeAnswer(false)
                        setSavedUserInput(lesson_id)
                    }
                    setFetchingPracticeAnswer(false)
                }


            })
            .catch(error => {
                console.log(error)

                if (error.response && error.response.status === 401) {
                    setUser(null)
                    setToken(null)
                    saveUserLogin(false)
                    setIsLogged(false)
                    push('/login')
                }
            });

    }

    let postAnswer = () => {

        setPostingAnswer(true)

        let data = {
            "answer_to_lesson": {
                "id": lessonData.id
            },
            "answer_text": answerInput
        }


        axios({
            method: "post",
            url: `${process.env.REACT_APP_API_PATH}/api/practice-answers/`,
            data: data,
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`
            },
        })
            .then(response => {
                if (response.status === 201) {
                    setPostingAnswer(false)
                    fetchPracticeAnswer(lesson.id)
                    removeLocalStoragePracticeData(lesson.id)
                }
            })
            .catch(error => {
                console.log(error);
                setPostingAnswer(false)
            });
    }



    let sumbitForm = e => {
        e.preventDefault()

        if (isLessonDeadline) {
            clearTimeout(typingTimeoutID)
            postAnswer()
        }

    }

    const getLocalStoragePracticeData = (lessonID) => {
        let data = localStorage.getItem('practiceLessonAnswers')
        if (data && isJson(data)) {

            data = JSON.parse(data)

            if (lessonID) {
                return data.filter(lesson => lesson.id === lessonID)
            }
            return data
        }
        return false
    }

    const setLocalStoragePracticeData = (data) => {
        let storedData = getLocalStoragePracticeData()

        if (storedData) {
            if (Array.isArray(storedData)) {
                let currentData = storedData.filter(lesson => lesson.id === data.id)

                if (currentData.length !== 0) {
                    currentData = currentData[0]
                    storedData = storedData.filter(lesson => lesson.id !== data.id)
                    currentData['userText'] = data['userText']
                    storedData.push(currentData)
                } else {
                    storedData.push(data)
                }
                localStorage.setItem('practiceLessonAnswers', JSON.stringify(storedData))
            }
        } else {
            localStorage.setItem('practiceLessonAnswers', JSON.stringify([data]))
        }
    }

    const removeLocalStoragePracticeData = (lessonID) => {
        let storedData = getLocalStoragePracticeData()

        if (storedData && Array.isArray(storedData)) {
            let updatedData = storedData.filter(lesson => lesson.id !== lessonID)
            localStorage.setItem('practiceLessonAnswers', JSON.stringify(updatedData))
            return true
        }

        return false
    }


    let saveUserInput = value => {
        const data = {
            'id': lesson.id,
            'userText': value
        }
        setLocalStoragePracticeData(data)
    }

    let setSavedUserInput = lessonID => {
        const data = getLocalStoragePracticeData(lessonID)

        if (
            data &&
            data.length > 0 &&
            'userText' in data[0]
        ) {
            setAnswerInput(data[0]['userText'])
        }
    }


    return (
        <div className='LessonTypePracticeAnswer'>

            <div className="lesson-answer">
                <div className="lesson-answer-heading">твоя відповідь</div>

                <div className="lesson-answer-main">

                    {fetchingPracticeAnswer ?
                        <img className='preloader preloader-fw black' src={preloader} alt='loading' />
                        :
                        (parcticeAnswer !== null ?

                            (
                                parcticeAnswer ?
                                    <div className="lesson-answer-submitted">
                                        <div className="answer-submitted-text">
                                            <div className='submitted-text-label'>Відправлено</div>
                                            <div className="submitted-text-value">
                                                <Linkify>{parcticeAnswer.answer_text ? newlineText(parcticeAnswer.answer_text) : null}</Linkify>
                                            </div>
                                        </div>
                                        {parcticeAnswer.mark ?
                                            <div className="answer-submitted-mark">
                                                <div className="submitted-mark-label">Оцінка</div>
                                                <div className="submitted-mark-value">

                                                    {lessonData.question_max_mark ? `${parcticeAnswer.mark}/${lessonData.question_max_mark}` : parcticeAnswer.mark}

                                                </div>
                                            </div>
                                            : null}

                                        {parcticeAnswer.comment ?
                                            <div className="answer-submitted-comment">
                                                <div className="submitted-comment-label">Коментар</div>
                                                <div className="submitted-comment-value">
                                                    <Linkify>{newlineText(parcticeAnswer.comment)}</Linkify>
                                                </div>
                                            </div>
                                            : null}

                                    </div>
                                    :

                                    <form
                                        className={`lesson-form ${!isLessonDeadline ? 'disabled' : ''}`}
                                        onSubmit={e => {
                                            sumbitForm(e)
                                        }}
                                    >

                                        {isLessonDeadline ?
                                            <textarea
                                                required={true}
                                                value={answerInput === null ? '' : answerInput}
                                                onChange={e => setAnswerInput(e.target.value)}
                                                maxLength={2000}
                                                disabled={!isLessonDeadline}
                                            />
                                            : null}

                                        <button
                                            type='submit'
                                            className='btn btn-sec'
                                            disabled={!isLessonDeadline}
                                        >
                                            {postingAnswer ? <img src={preloader} alt="loading" /> : 'Відправити'}
                                        </button>
                                    </form>
                            )
                            : null)
                    }
                </div>

            </div>

        </div>
    )
}
