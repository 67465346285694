import { Link } from 'react-router-dom'
import { seoTitle } from '../../../../helpers/functions'

export default function NotFoundContent() {

    seoTitle('404 Not Found')

    return (
        <div className="NotFoundContent">
            <div className="notfound-text">
                Not Found
            </div>
            <Link className='btn notfound-link' to='/'>головна</Link>
        </div>
    )
}
