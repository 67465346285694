import axios from 'axios';
import {useContext, useState, useEffect, useRef} from 'react';
import {GlobalContext} from '../../../../helpers/context/GlobalState';

export default function LessonScoreForm( {lesson, navigationClicked, setScoreFromSubmitedNavigation} ) {
	const formRef = useRef(null);
	const {globalState} = useContext( GlobalContext );
	const {token} = globalState;
	const [isScoreAlreadySet, setIsScoreAlreadySet] = useState( false );
	const [zeroScore, setZeroScore] = useState( false );
	const [scoreSubmited, setScoreSubmited] = useState( false );
	const [formData, setFormData] = useState( {
		speaker_score: 0,
		theme_score: 0,
		message: '',
	} );

	useEffect( () => {
		setScoreFromSubmitedNavigation( false );
		isScoreAlreadySetFetch();

		// eslint-disable-next-line
	}, [] );

	const handleShake = () => {
		if(formRef.current) {
			formRef.current.classList.add('shake');
			setTimeout(() => {
				formRef.current.classList.remove('shake');
			}, 1000);
		}
	};

	const handleScroll = () => {
		if(formRef.current)  {
			formRef.current.scrollIntoView({ behavior: 'smooth' });
		}
	};

	if (navigationClicked && ! scoreSubmited) {
		handleShake();
		handleScroll();
	}

	const isScoreAlreadySetFetch = () => {

		axios( {
			method: 'post',
			url: `${process.env.REACT_APP_API_PATH}/api/check-feedback/`,
			data: {
				'lesson_id': lesson.id,
			},
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Token ${token}`,
			},
		} ).then( response => {
			if (response.status === 200) {

				if(response.data.user_feedback) {
					setIsScoreAlreadySet( true );
					setScoreFromSubmitedNavigation( true );
				}

			}
		} ).catch( error => {
			console.log( error );
		} );
	};

	const handleSubmit = ( e ) => {
		e.preventDefault();


		if (formData.speaker_score == 0 || formData.theme_score == 0) {
			setZeroScore( true );
		} else {
			setZeroScore( false );

			axios( {
				method: 'post',
				url: `${process.env.REACT_APP_API_PATH}/api/add-feedback/`,
				data: {
					'lesson_id': lesson.id,
					formData: formData,
				},
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Token ${token}`,
				},
			} ).then( response => {
				if ( response.status === 201 ) {
					setScoreSubmited( true );
					setScoreFromSubmitedNavigation( true );
					console.log( 'Score Submited successfully!', response );
				}
			} ).catch( error => {
				console.error( 'Error submitting score:', error );
			} );
		}

	};

	return (
		<div>
			{isScoreAlreadySet ? (
				<span></span>
			) : (
				<>
					{scoreSubmited ? (
						<form ref={formRef} className="LessonScoreForm">
							<p>Дякуємо за оцінку!</p>
						</form>
					) : (
						<form ref={formRef} className="LessonScoreForm" onSubmit={handleSubmit}>
							{zeroScore ? (
								<p className="error">Ви не можете поставити 0 балів</p>
							) : null}
							<div className="lesson-score-form-range">
								<label htmlFor="speaker_score">
									<div className="label">Поставте оцінку спікеру від 1 до 10</div>
									<div>
										<input required type="range" id="speaker_score" min="0" max="10" list="speaker_score_list" value={formData.speaker_score} onChange={( e ) => setFormData( {
											...formData,
											speaker_score: e.target.value,
										} )}></input>
										<div className="lesson-score-form-datalist" id="speaker_score_list">
											<span className="lesson-score-form-option" value="0" label="0">0</span>
											<span className="lesson-score-form-option" value="1" label="1">1</span>
											<span className="lesson-score-form-option" value="2" label="2">2</span>
											<span className="lesson-score-form-option" value="3" label="3">3</span>
											<span className="lesson-score-form-option" value="4" label="4">4</span>
											<span className="lesson-score-form-option" value="5" label="5">5</span>
											<span className="lesson-score-form-option" value="6" label="6">6</span>
											<span className="lesson-score-form-option" value="7" label="7">7</span>
											<span className="lesson-score-form-option" value="8" label="8">8</span>
											<span className="lesson-score-form-option" value="9" label="9">9</span>
											<span className="lesson-score-form-option" value="10" label="10">10</span>
										</div>
									</div>
								</label>

								<label htmlFor="theme_score">
									<div className="label">Поставте оцінку важливості теми від 1 до 10</div>
									<div>
										<input required type="range" id="theme_score" min="0" max="10" list="theme_score_list" value={formData.theme_score} onChange={( e ) => setFormData( {
											...formData,
											theme_score: e.target.value,
										} )}></input>
										<div className="lesson-score-form-datalist" id="theme_score_list">
											<span className="lesson-score-form-option" value="0" label="0">0</span>
											<span className="lesson-score-form-option" value="1" label="1">1</span>
											<span className="lesson-score-form-option" value="2" label="2">2</span>
											<span className="lesson-score-form-option" value="3" label="3">3</span>
											<span className="lesson-score-form-option" value="4" label="4">4</span>
											<span className="lesson-score-form-option" value="5" label="5">5</span>
											<span className="lesson-score-form-option" value="6" label="6">6</span>
											<span className="lesson-score-form-option" value="7" label="7">7</span>
											<span className="lesson-score-form-option" value="8" label="8">8</span>
											<span className="lesson-score-form-option" value="9" label="9">9</span>
											<span className="lesson-score-form-option" value="10" label="10">10</span>
										</div>
									</div>
								</label>
							</div>

							<label htmlFor="message">
								<div className="label">Надайте коментар, що було добре, а що можна покращити</div>

								<textarea id="message" value={formData.message} onChange={( e ) => setFormData( {
									...formData,
									message: e.target.value,
								} )}></textarea>
							</label>

							<button className="btn btn-sec" type="submit">Відправити</button>
						</form>
					)}
				</>
			)}
		</div>
	);
}
