import { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router'
import axios from 'axios'
import { GlobalContext } from '../../../../helpers/context/GlobalState'
import preloader from '../../../../images/preloader.svg'
import { saveUserLogin } from '../../../../helpers/functions'

export default function BlockGeneralInfo() {

    const { push } = useHistory();

    const { globalState, setUser, setIsLogged, setToken } = useContext(GlobalContext)
    const { token, user } = globalState

    const [fetchingGeneralInfo, setFetchingGeneralInfo] = useState(true)
    const [isEditing, setIsEditing] = useState(false)
    const [postingEdits, setPostingEdits] = useState(false)

    //inputs
    const [firstNameInput, setFirstNameInput] = useState('')
    const [lastNameInput, setLastNameInput] = useState('')

    useEffect(() => {

        getData()

        // eslint-disable-next-line
    }, [])


    let getData = () => {
        setFetchingGeneralInfo(true)

        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_PATH}/api/user/general-info`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`
            },
        })
            .then(response => {
                setFetchingGeneralInfo(false)

                if (response.status === 200) {

                    if (['first_name'] in response.data) {
                        setFirstNameInput(response.data.first_name)
                    }

                    if (['last_name'] in response.data) {
                        setLastNameInput(response.data.last_name)
                    }


                }
            })
            .catch(error => {
                setFetchingGeneralInfo(false)

                if (error.response && error.response.status === 401) {
                    setUser(null)
                    setToken(null)
                    saveUserLogin(false)
                    setIsLogged(false)
                    push('/login')
                }
            });
    }

    let postData = () => {

        if (user.first_name !== firstNameInput || user.last_name !== lastNameInput) {

            setPostingEdits(true)

            let data = {
                'first_name': firstNameInput.slice(0,30),
                'last_name': lastNameInput,
            }

            axios({
                method: "post",
                url: `${process.env.REACT_APP_API_PATH}/api/user/general-info`,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Token ${token}`
                },
                data: JSON.stringify(data)
            })
                .then(response => {

                    if (response.status === 200) {

                        if (['first_name'] in response.data && ['last_name'] in response.data) {

                            setFirstNameInput(response.data.first_name)
                            setLastNameInput(response.data.last_name)

                            setUser({ ...user, first_name: response.data.first_name, last_name: response.data.last_name })
                            saveUserLogin({ ...user, first_name: response.data.first_name, last_name: response.data.last_name }, token)
                        }


                        setPostingEdits(false)
                        setIsEditing(false)
                    }
                })
                .catch(error => {
                    setPostingEdits(false)
                    setIsEditing(false)

                    if (error.response && error.response.status === 401) {
                        setUser(null)
                        setToken(null)
                        saveUserLogin(false)
                        setIsLogged(false)
                        push('/login')
                    }
                });

        } else {
            setIsEditing(false)
        }


    }


    let saveEdits = (isEditing) => {
        if (!postingEdits) {

            if (isEditing === true) {
                postData()
            } else {
                setIsEditing(true)
            }

        }
    }


    return (
        <div className='BlockGeneralInfo'>
            <div className="block-content">
                <div className="block-head">
                    Мої дані
                </div>

                <div className="general-info-main">

                    {fetchingGeneralInfo ?
                        <img className='preloader preloader-fw black' src={preloader} alt='loading' />
                        :
                        <div className="block-fields">
                            <div className="field-item">
                                <div className="item-label">
                                    Логін
                                </div>

                                <input
                                    className='item-input'
                                    type="text"
                                    value={user && user.username ? user.username : 'No username'}
                                    readOnly={true}
                                />
                            </div>

                            <div className="field-item">
                                <div className="item-label">
                                    Імʼя
                                </div>

                                <input
                                    className='item-input'
                                    type="text"
                                    value={firstNameInput}
                                    onChange={e => setFirstNameInput(e.target.value.trim().slice(0,30))}
                                    disabled={!isEditing}
                                />
                            </div>
                            <div className="field-item">
                                <div className="item-label">
                                    Прізвище
                                </div>

                                <input
                                    className='item-input'
                                    type="text"
                                    value={lastNameInput}
                                    onChange={e => setLastNameInput(e.target.value.trim())}
                                    disabled={!isEditing}
                                />

                            </div>
                        </div>
                    }

                    {!fetchingGeneralInfo ?
                        <div className="block-action">
                            <div
                                className="btn btn-sec btn-general-info"
                                onClick={() => saveEdits(isEditing)}
                            >
                                {postingEdits ?
                                    <img className='preloader black' src={preloader} alt='loading' />
                                    : (isEditing ? 'Зберегти' : 'Змінити')}
                            </div>
                        </div>
                        : null}
                </div>

            </div>
        </div>
    )
}