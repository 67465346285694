import './style/style.scss'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useEffect, useContext, useState } from 'react';
import { GlobalContext } from './helpers/context/GlobalState';
import { saveUserLogin } from './helpers/functions';
import LoginPage from './components/pages/LoginPage';
import Dashboard from './components/pages/Dashboard';
import PrivateRoute from './components/parts/PrivateRoute';
import Header from './components/parts/layout/Header';
import CoursePage from './components/pages/CoursePage';
import LessonPage from './components/pages/LessonPage';
import NotFound from './components/pages/NotFound';
import PersonalCabinet from './components/pages/PersonalCabinet'
import Certificate from './components/pages/Certificate'
import ResetPasswordChange from './components/pages/ResetPasswordChange';
import ResetPasswordRequest from './components/pages/ResetPasswordRequest';
import { saveUserTheme } from './helpers/functions';

function App() {

  const { setUser, setToken, setIsLogged, setTheme, globalState } = useContext(GlobalContext)
  const [ loadedLoginInfo, setLoadedLoginInfo ] = useState(false)
  const [ loadedThemeInfo, setLoadedThemeInfo ] = useState(false)

  useEffect(() => {
    let user = localStorage.getItem('user')
    let token = localStorage.getItem('token')

    try {
        user = JSON.parse(user);
        token = JSON.parse(token);
    } catch (err) {
        user = null
        token = null
    }

    if(
      user && 
      'username' in user && 
      'first_name' in user && 
      'last_name' in user && 
      'id' in user && 
      token
    ){
      setUser(user)
      setToken(token)
      setIsLogged(true)
    } else {
      saveUserLogin(false)
    }

    setLoadedLoginInfo(true)

  // eslint-disable-next-line
  }, []);


  useEffect(() => {
    let theme = localStorage.getItem('app_theme')

    try {
      theme = JSON.parse(theme);
    } catch (err) {
      theme = null
    }

    if(theme){
      setTheme(theme)
    } else {
      setTheme('light')
      saveUserTheme('light')
    }

    setLoadedThemeInfo(true)

  // eslint-disable-next-line
  }, []);


  if(loadedLoginInfo && loadedThemeInfo){
    return (
      <Router>
        <div className={`App ${globalState.user_theme === 'dark' ? 'dark' : ''}`}>
            <>
              <Header />
              <Switch>
                <Route path="/login" component={LoginPage} />
                <PrivateRoute exact path="/" component={Dashboard}/>
                <PrivateRoute exact path="/account" component={PersonalCabinet}/>
                <PrivateRoute exact path="/course/:course_id" component={CoursePage}/>
                <PrivateRoute exact path="/course/:course_id/:lesson_id" component={LessonPage}/>
                <Route exact path="/student-certificate/:certificate_id" component={Certificate}/>
                <Route exact path="/reset-password/change" component={ResetPasswordChange}/>
                <Route exact path="/reset-password/request" component={ResetPasswordRequest}/>
                <Route component={NotFound}/>
              </Switch>
            </>
          </div>
      </Router>
    )
  } else return null
  

  
}

export default App;
