import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import axios from 'axios'
import { GlobalContext } from '../../helpers/context/GlobalState'
import preloader from '../../images/preloader.svg'
import { saveUserLogin, seoTitle } from '../../helpers/functions'
import CourseItem from '../parts/layout/Dashboard/CourseItem'

export default function Dashboard() {

    const { push } = useHistory();

    const { globalState, setUser, setIsLogged, setToken } = useContext(GlobalContext)
    const { token } = globalState

    const [ isLoading, setIsLoading ] = useState(true)
    const [ courseList, setCourseList ] = useState([])

    useEffect(()=>{

        seoTitle('Dashboard')

        setIsLoading(true)

        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_PATH}/api/courses/`,
            headers: { 
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`
            },
        })
        .then(response => {
            setIsLoading(false)

            if(response.status === 200){
                setCourseList(response.data)
            }

        })
        .catch(error => {
            setIsLoading(false)

            if(error.response && error.response.status === 401){
                setUser(null)
                setToken(null)
                saveUserLogin(false)
                setIsLogged(false)
                push('/login')
            }
        });


    // eslint-disable-next-line
    }, [])


    return (
        <div className='Dashboard page-wrap'>
            <div className="container">
                <div className="page-title page-title-center large">
                    Мої курси
                </div>

                <div className="course-list">
                    {isLoading ? 
                        <img className='preloader preloader-fw' src={preloader} alt='loading'/> 
                    : 
                    
                        (
                            Array.isArray(courseList) && courseList.length > 0 ? 
                            courseList.map((course, index)=>{
                                return (
                                    <CourseItem key={index} course={course}/>
                                )
                            })

                            : 
                            
                            <div className='no-data'>Немає доступних курсів :(</div>
                        )
                    
                    }

                </div>
            </div>
        </div>
    )
}
