import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom'
import { GlobalContext } from '../../helpers/context/GlobalState'
import { saveUserLogin, seoTitle } from '../../helpers/functions';
import axios from 'axios';
import preloader from '../../images/preloader.svg'
import NotFoundContent from '../parts/layout/NotFound/NotFoundContent';
import CourseRating from '../parts/layout/CoursePage/CourseRating';
import ModulesList from '../parts/layout/CoursePage/ModulesList';
import BackButton from '../parts/layout/BackButton';
import CourseCalendar from '../parts/layout/CoursePage/CourseCalendar';


export default function CoursePage(props) {

    const { push } = useHistory();

    const { globalState, setUser, setToken, setIsLogged } = useContext(GlobalContext);
    const { token } = globalState

    const [isLoading, setIsLoading] = useState(false)
    const [fetchEnded, setFetchEnded] = useState(false)
    const [courseData, setCourseData] = useState(null)
    const [hasCourseAccess, setHasCourseAccess] = useState(true)
    const [currentCourseID, setCurrentCourseID] = useState(props.match.params.course_id);
    const [passQualifyingCourse, setPassQualifyingCourse] = useState(false)

    useEffect(() => {

        setCourseData(null)
        setIsLoading(true)
        setFetchEnded(false)
        setHasCourseAccess(true)
        setPassQualifyingCourse(false)

        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_PATH}/api/courses/?course_id=${currentCourseID}`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`
            },
        })
            .then(response => {

                if (response.status === 200) {
                    if (Array.isArray(response.data) && response.data.length > 0) {

                        const resData = response.data[0]

                        if ('qualifying_course' in resData) {
                            setHasCourseAccess(resData['qualifying_course']['has_access'])
                        }

                        if ('main_course' in resData && 'qualifying_course_pass' in resData && 'user_percentage' in resData) {
                            setPassQualifyingCourse(resData['user_percentage'] >= resData['qualifying_course_pass'])
                        }

                        setCourseData(resData)
                        seoTitle(resData.course_name)
                    }
                }

                setFetchEnded(true)
                setIsLoading(false)
            })
            .catch(error => {
                setIsLoading(false)
                setFetchEnded(true)

                if (error.response && error.response.status === 401) {
                    setUser(null)
                    setToken(null)
                    saveUserLogin(false)
                    setIsLogged(false)
                    push('/login')
                }
            });

        // eslint-disable-next-line
    }, [currentCourseID])



    if (fetchEnded && !courseData) {
        return <NotFoundContent />
    }

    return (
        <div className='CoursePage page-wrap'>
            <div className="container">

                {isLoading ? <img className='preloader preloader-fw' src={preloader} alt='loading' /> : null}

                {!isLoading ?

                    (
                        courseData && courseData.length !== 0 ?

                            <>
                                <BackButton link={'/'} label="Мої курси" />

                                {courseData.course_subtitle ?
                                    <div className="course-subtitle">
                                        {courseData.course_subtitle}
                                    </div>
                                    : null}

                                <div className="course-title">
                                    {courseData.course_name}
                                </div>

                                {hasCourseAccess && courseData.course_stats ?
                                    <div className="course-stats">
                                        {courseData.course_stats.split('\r\n').map((block, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className="stats-block"
                                                >
                                                    <div className="stats-value">{block.split(' ')[0]}</div>
                                                    <div className="stats-label">{block.split(' ').slice(1).join(' ')}</div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    : null}

                                {hasCourseAccess && courseData.course_description ?

                                    <div className="course-description">
                                        <div className="course-description-heading">
                                            Про курс
                                        </div>
                                        <div className="course-description-text">
                                            {courseData.course_description}
                                        </div>
                                    </div>

                                    : null}

                                <div className="course-content">

                                    <div className="course-content-main">

                                        {hasCourseAccess ?

                                            <>
                                                <ModulesList
                                                    course_id={currentCourseID}
                                                    courseData={courseData}
                                                    passQualifyingCourse={passQualifyingCourse}
                                                    setCurrentCourseID={setCurrentCourseID}
                                                />

                                                <div className="course-sidebar">

                                                    {
                                                        'modules' in courseData &&
                                                            Array.isArray(courseData.modules) &&
                                                            courseData.modules.length > 0
                                                            ?
                                                            <CourseCalendar
                                                                course_id={currentCourseID}
                                                                modules={courseData.modules}
                                                                calendar_url={courseData.google_calendar_link}
                                                            />
                                                            : null}

                                                    {!courseData.disable_student_rating ?
                                                        <CourseRating
                                                            course_id={currentCourseID}
                                                        />
                                                        : null}

                                                </div>

                                            </>

                                            :

                                            <div className="course-no-access">
                                                <p>
                                                    {`Курс доступний для студентів, які пройшли відбіркові завдання та мають рейтинг ${courseData['qualifying_course']['qualifying_course_pass']}% та вище.`}
                                                </p>
                                                <p>
                                                    {`Ваш поточний рейтинг ${courseData['qualifying_course']['qualifying_course_user_persentage']}%`}
                                                </p>

                                                <Link
                                                    className='btn'
                                                    to={`/course/${courseData['qualifying_course']['id']}`}
                                                    onClick={() => {
                                                        setCurrentCourseID(courseData['qualifying_course']['id'])
                                                    }}
                                                >
                                                    {courseData['qualifying_course']['course_name']}
                                                </Link>
                                            </div>

                                        }


                                    </div>

                                </div>
                            </>
                            : null
                    )
                    : null}


            </div>
        </div>
    )
}
