import { React, useEffect, useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { GlobalContext } from '../../helpers/context/GlobalState';
import preloader from '../../images/preloader.svg'
import axios from 'axios';
import { getLessonTypeLabel, isLessonMeetDealine,  saveUserLogin, isLessonAvailable, dateTimeCoutdownMarkup, seoTitle, hasAccessLesson } from '../../helpers/functions';
import LessonUnvailable from '../parts/layout/LessonPage/LessonUnavailable';
import LessonTypeLecture from '../parts/layout/LessonPage/LessonTypeLecture';
import LessonTypePractice from '../parts/layout/LessonPage/LessonTypePractice';
import LessonTypeQA from '../parts/layout/LessonPage/LessonTypeQA';
import LessonTypeTest from '../parts/layout/LessonPage/LessonTypeTest/LessonTypeTest';
import LessonTypeLivestream from '../parts/layout/LessonPage/LessonTypeLivestream/LessonTypeLivestream';
import LessonTypeWebinar from '../parts/layout/LessonPage/LessonTypeWebinar/LessonTypeWebinar';
import LessonMaterials from '../parts/layout/LessonPage/LessonMaterials';
import NotFoundContent from '../parts/layout/NotFound/NotFoundContent';
import BackButton from '../parts/layout/BackButton';
import LessonTypePracticeAnswer from '../parts/layout/LessonPage/LessonTypePracticeAnswer';
import LessonNavigation from '../parts/layout/LessonPage/LessonNavigation';
import LessonTypeLivestreamChat from '../parts/layout/LessonPage/LessonTypeLivestream/LessonTypeLivestreamChat';
import LessonTypeWebinarChat from '../parts/layout/LessonPage/LessonTypeWebinar/LessonTypeWebinarChat';
import LessonScoreForm from '../parts/layout/LessonPage/LessonScoreForm';

export default function LessonPage(props) {

    const { push } = useHistory();

    const { globalState, setUser, setToken, setIsLogged } = useContext(GlobalContext);
    const { token } = globalState

    const [ isLoading, setIsLoading ] = useState(false)
    const [ fetchEnded, setFetchEnded ] = useState(false)
    const [ currentLesson, setCurrentLesson ] = useState(null)
    const [ currentCourseName, setCurrentCourseName ] = useState(null)
    const [ lessonCutdown, setLessonCutdown ] = useState(null)
    const [ isLessonDeadline, setIsLessonDeadline ] = useState(false)
    const [ showLessonsNavigation, setShowLessonsNavigation ] = useState(true)
    const [ userPercentage, setUserPercentage ] = useState(null)
    const [ nextLessonID, setNextLessonID ] = useState(null)
    const [ prevLessonID, setPrevLessonID ] = useState(null)
    const [ scoreFromSubmited, setScoreFromSubmitedNavigation ] = useState(null)
    const [ navigationClicked, setNavigationClicked ] = useState(null)

    // only for practice type lesson
    const [ hasPracticeAnswer, setHasPracticeAnswer ] = useState(null)


    let { course_id, lesson_id } = props.match.params

    useEffect(()=>{

        setHasPracticeAnswer(null)
        fetchLesson()

        return ()=>{
            clearInterval('cutdownInterval')
        }

    // eslint-disable-next-line
    }, [props.match.params.lesson_id])



    const lessonCutdownInit = (available_from) => {

        let _cutdown = () => {
            if(!isLessonAvailable(available_from)){
                setLessonCutdown(dateTimeCoutdownMarkup(available_from))
            }    
        }

        _cutdown()
        setInterval(function cutdownInterval(){
            _cutdown()
        }, 1000);
    }


    const fetchLesson = () => {
        setFetchEnded(false)
        setIsLoading(true)

        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_PATH}/api/lesson/?lesson_id=${lesson_id}&course_id=${course_id}`,
            headers: { 
                "Content-Type": "application/json",
                "Authorization": `Token ${token}`
            },
        })
        .then(response => {

            if(response.status === 200){
                const data = response.data

                setCurrentCourseName(data.current_course_name)
                setCurrentLesson(data.lesson_data)
                lessonCutdownInit(data.lesson_data.available_from)
                setUserPercentage(data.user_percentage)
                setIsLessonDeadline(isLessonMeetDealine(data.lesson_data.deadline_to))
                setNextLessonID(data.next_lesson_id)
                setPrevLessonID(data.prev_lesson_id)
                seoTitle(data.lesson_data.lesson_name)

            }

            setIsLoading(false)
            setFetchEnded(true)

        })
        .catch(error => {
            setIsLoading(false)
            setFetchEnded(true)

            if(error.response && error.response.status === 401){
                setUser(null)
                setToken(null)
                saveUserLogin(false)
                setIsLogged(false)
                push('/login')
            }
        });
    }


    const getNextLesson = (next_lesson_id) => {

        if(next_lesson_id){
            return `/course/${course_id}/${next_lesson_id}` 
        }

        return null
    }

    const getPrevLesson = (prev_lesson_id) => {

        if(prev_lesson_id){
            return `/course/${course_id}/${prev_lesson_id}` 
        }

        return null
    }

    
    if(fetchEnded && !currentLesson){
        return <NotFoundContent />
    }

    return (
        <div className='LessonPage page-wrap'>
            <div className="container">
                {isLoading ? <img className='preloader preloader-fw' src={preloader} alt='loading'/> : null}


                {fetchEnded && currentLesson ? 

                (
                    <>
                        
                        <BackButton label={currentCourseName} link={`/course/${course_id}`} />
                        
                        <div className="lesson-title">
                            <div className={`lesson-type-label type-${currentLesson.lesson_type}`}>{getLessonTypeLabel(currentLesson.lesson_type)}</div>
                            <div className="lesson-title-text">
                                {currentLesson.lesson_name}
                            </div>
                        </div>


                        

                        <div 
                            className={
                                `lesson-content 
                                    ${
                                        currentLesson.lesson_type === 4 || 
                                        !isLessonAvailable(currentLesson.available_from) ||
                                        !hasAccessLesson(currentLesson.limited_access_percentage, userPercentage) 
                                        
                                        ?
                                        
                                        'lesson-content-full-width' : ''
                                    }
                                `
                            }
                        >

                            {isLessonAvailable(currentLesson.available_from) ? 

                                <>
                                    {
                                        hasAccessLesson(currentLesson.limited_access_percentage, userPercentage) ? 


                                            <>
                                                <div className="lesson-content-main">

                                                    {currentLesson.lesson_type === 1 ?
                                                        <LessonTypeLecture lesson={currentLesson} />
                                                    :null}

                                                    {currentLesson.lesson_type === 2 ?
                                                        <LessonTypePractice 
                                                            isLessonDeadline={isLessonDeadline} 
                                                            lesson={currentLesson}
                                                            hasPracticeAnswer={hasPracticeAnswer}
                                                        />
                                                    :null}

                                                    {currentLesson.lesson_type === 3 ?
                                                        <LessonTypeQA lesson={currentLesson} />
                                                    :null}

                                                    {currentLesson.lesson_type === 4 ?
                                                        <LessonTypeTest 
                                                            isLessonDeadline={isLessonDeadline} 
                                                            lesson={currentLesson}
                                                            setShowLessonsNavigation={setShowLessonsNavigation}
                                                        />
                                                    :null}

                                                    {currentLesson.lesson_type === 5 ?
                                                        <LessonTypeLivestream 
                                                            lesson={currentLesson}
                                                        />
                                                    :null}

                                                    {currentLesson.lesson_type === 6 ?
                                                        <LessonTypeWebinar
                                                            lesson={currentLesson}
                                                        />
                                                    :null}

                                                    {currentLesson.lesson_type === 1 || currentLesson.lesson_type === 5 ?
                                                    <LessonScoreForm
                                                        lesson={currentLesson}
                                                        navigationClicked={navigationClicked}
                                                        setScoreFromSubmitedNavigation={setScoreFromSubmitedNavigation}
                                                    />
                                                    :null}

                                                </div>

                                                <div className="lesson-content-sidebar">

                                                    {'lesson_type' in currentLesson && currentLesson['lesson_type'] === 5 ?
                                                        <LessonTypeLivestreamChat lesson={currentLesson} />
                                                    :null}

                                                    {'lesson_type' in currentLesson && currentLesson['lesson_type'] === 6 ?
                                                        <LessonTypeWebinarChat lesson={currentLesson} />
                                                        :null}

                                                    {currentLesson.lesson_materials.length !== 0 ?
                                                        <LessonMaterials 
                                                            materials={currentLesson.lesson_materials}
                                                        />
                                                    :null}



                                                    {'lesson_type' in currentLesson && currentLesson['lesson_type'] === 2 ? 
                                                        <LessonTypePracticeAnswer 
                                                            isLessonDeadline={isLessonDeadline} 
                                                            lesson={currentLesson}
                                                            setHasPracticeAnswer={setHasPracticeAnswer}
                                                        />
                                                    :null}

                                                    {showLessonsNavigation ?
                                                        <LessonNavigation
                                                            lesson={currentLesson}
                                                            setNavigationClicked={setNavigationClicked}
                                                            scoreFromSubmited={scoreFromSubmited}
                                                            nextLink={getNextLesson(nextLessonID)}
                                                            prevLink={getPrevLesson(prevLessonID)}
                                                        />
                                                    :null}

                                                </div>
                                            </>
                                        :
                                            <>
                                                <div className="lesson-is-not-available">
                                                    {`Заняття доступно для студентів із рейтингом ${currentLesson.limited_access_percentage}% та вище. Ваш поточний рейтинг - ${userPercentage}%`}
                                                </div>
                                                <LessonNavigation
                                                    lesson={currentLesson}
                                                    setNavigationClicked={setNavigationClicked}
                                                    scoreFromSubmited={scoreFromSubmited}
                                                    nextLink={getNextLesson(nextLessonID)}
                                                    prevLink={getPrevLesson(prevLessonID)}
                                                />
                                            </>
                                    }
                                </>
                            
                            :
                                <>
                                    <LessonUnvailable cutdown={lessonCutdown} />
                                    <LessonNavigation
                                        lesson={currentLesson}
                                        setNavigationClicked={setNavigationClicked}
                                        scoreFromSubmited={scoreFromSubmited}
                                        nextLink={getNextLesson(nextLessonID)}
                                        prevLink={getPrevLesson(prevLessonID)}
                                    />
                                </>
                            }

                            
                        </div>
                    </>
                )
                :null}
            </div>
            
        </div>
    )
}
