export default function AppReducer(state, action){
    switch (action.type) {
        case 'SET_USER':
            return {
                ...state,
                user: action.payload
            }

        case 'SET_TOKEN':
            return {
                ...state,
                token: action.payload
            }

        case 'SET_ISLOGGED':
            return {
                ...state,
                isLogged: action.payload
            }

        case 'SET_THEME':
            return {
                ...state,
                user_theme: action.payload
            }

        default: return state
    }
}