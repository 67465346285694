import LessonItem from './LessonItem'
import { formateDateTime } from '../../../../helpers/functions';

export default function ModuleItem({ position, module, course_id, user_percentage }) {

    const isAvailable = 'is_available' in module && module['is_available'];
    const availableFrom = 'available_from' in module && module['available_from'];

    return (
        <div className='ModuleItem'>
            <div className="module-position">
                {
                    (`${position}`.length === 1 && `${position}` !== '0') ? `0${position}.` :
                    (`${position}` === '0') ? `start` :
                    `${position}.`
                }
            </div>

            <div className="module-name">
                {module.module_name}
            </div>

            <div className="module-description" dangerouslySetInnerHTML={{ __html: module.module_description }}></div>

            {isAvailable ?

                <div className="module-lessons">
                    {
                        'lessons' in module &&
                        Array.isArray(module.lessons) &&
                        module.lessons.length !== 0 &&
                        module.lessons.map((lesson, index) => {

                            return (
                                <LessonItem key={index} lesson={lesson} course_id={course_id} user_percentage={user_percentage} />
                            )

                        })
                    }

                </div>

                :

                <div className="module-closed">
                    {
                        availableFrom ? `Модуль буде доступний ${formateDateTime(availableFrom)}` : 'Модуль наразі недоступний'
                    }
                </div>

            }


        </div>
    )
}
