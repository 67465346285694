import React from 'react'

export default function TestStartView({lessonData, questionsTotal, setTestStartView, testDuration, userAnswers, setLocalStorageTestData, setShowLessonsNavigation, isLessonDeadline}) {


    return (
        <div className={`TestStartView ${!isLessonDeadline ? 'disabled' :''}`}>
            {lessonData.test_description ? 
                <div className={`test-description ${!isLessonDeadline ? 'disabled' :''}`}>
                    <div className="test-description-heading">Важливо:</div>
                    <div className="test-description-text" dangerouslySetInnerHTML={{ __html: lessonData.test_description }}></div>
                </div>
            :null}

            <div className={`test-info ${!isLessonDeadline ? 'disabled' :''}`}>

                <div className="test-info-item">
                    <div className="info-item-label">
                        Питань
                    </div>
                    <div className="info-item-value">
                        {questionsTotal}
                    </div>
                </div>

                <div className="test-info-item">
                    <div className="info-item-label">
                        Тривалість
                    </div>
                    <div className="info-item-value">
                        {`${testDuration} хв`}
                    </div>
                </div>
                
            </div>


            <div 
                className={`btn btn-sec start-test-btn ${!isLessonDeadline ? 'disabled' : ''}`}
                onClick={()=>{
                    if(isLessonDeadline){
                        setTestStartView(false)
                        setShowLessonsNavigation(false)
                        setLocalStorageTestData({
                            'testStarted': new Date().getTime(),
                            'id' : lessonData.id,
                            'questions' : userAnswers             
                        })
                    }
                }}
            >
                Почати тест
            </div>
        </div>
    )
}
