import { useEffect, useState } from 'react'
import LessonVideo from './LessonVideo'

export default function LessonTypeQA({lesson}) {

    const [ lessonData, setLessonData ] = useState(null)
    const [ videoUrlEmbed, setVideoUrlEmbed ] = useState(null)

    useEffect(()=>{
        if(lesson.type_qa.length !== 0){
            setLessonData(lesson.type_qa[0])

            if(lesson.type_qa[0].video_embed_url && lesson.type_qa[0].video_embed_url !== ''){
                let video_urls = lesson.type_qa[0].video_embed_url.replace(/\s+/g, '').split(',')
                setVideoUrlEmbed(video_urls)
            }
        }
    // eslint-disable-next-line
    }, [])


    if(lessonData){
        return (
            <div className='LessonTypeQA'>
    
                {Array.isArray(videoUrlEmbed) && videoUrlEmbed.length !== 0 ?

                    videoUrlEmbed.map((url, index) => {
                        return (
                            <LessonVideo
                                key={index}
                                embedURL={url}
                                lesson={lesson}
                            />
                        )
                    
                    })
                    
                :null}
                
    
                {lessonData.lesson_text ?
                    <div className="lesson-main">
                        <div className="lesson-text" dangerouslySetInnerHTML={{ __html: lessonData.lesson_text }}></div>
                    </div>
                :null}
                
            </div>
        )
    } else return null


    
}
